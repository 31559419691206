export const environment = {
  applications: {
    applications: ['CLAAS_connect'],
    shop_applications: [
      'Sao9Ggz3Hn3KWpj6JqyuuAxCWhOwsthx',
      'WDgoUQHGSSsU2lvb3cTcUTMw9HHwRIrW',
      'QvskCmBK3byUppUhVf8V6c5pD8AMFMwv',
      'N5UGKzcuhlPpFHWT8USpz5eBM7m7IfIe',
      'iAysuSj70VHhTAbSrWhZAOs6NRU7ioko',
      '5vA1JUVdWQG320YqRvsaRABD25DsWGE2',
      'okvxNon88NESWOpU1LmZqthbtCRyIFOL',
      '7i1Kl3fX6HKCMbDrL0KrBAzAlTgRZkDd',
      '6M9DWaLW0YCMdyq6ncy0IELzHviToBiX',
      'DFP5Yh2Gz8I9LVeosoNScZShibw3V8fH',
      'jNJa097aa7WzcPQ8JFwQsQ0RGleFBf9Z',
      'YFWy1lzlMpktO32q9bihUw19HFNBiEyb',
      'cOYwGOaNdRXkJNHnPppERxlFoKPWXvpX',
      'HYWLEexJw1UcmCMOWdwxb3UMOiM6Vpx5',
      'fr3QJUrYockr1bvApdYJrarJmVP3XFtM',
      'RsD3Lm6uiMJDC5JNekg37mfPYSzRJEh3',
      'eg2hcismLYJIWgZ9O0wHNXxZpfjzF6wf'
    ]
  },
  auth: {
    auth_audience: 'https://user-api.accounts.f67a9d15.pincubator.tech',
    auth_client_id: 'He6Dwzxre2FqrpXvay0S27tHl3xHmhLQ',
    auth_domain: 'login.f67a9d15.pincubator.tech',
    auth_token_key: 'api_auth_token',
    azure_maps_client_id: '1ad08a02-4767-4ec5-b0a1-2ce77f280b4e',
    unauthed_routes: [
      '/org-selection',
      '/info-page',
      '/submit',
      '/complete',
      '/shop-page',
      '/callback',
      '/verified',
      '/info',
      '/privacy'
    ]
  },
  base_urls: {
    api_base_url: 'https://api.accounts.f67a9d15.pincubator.tech',
    app_base_url: 'https://accounts.f67a9d15.pincubator.tech',
    cc_3_base_url: 'https://93c13274.pincubator.tech/',
    cc_3_api_base_url: 'https://api.93c13274.pincubator.tech',
    cc_app_base_url: 'com.claas.store.connect://',
    cc_3_app_base_url: 'com.claas.store.connect://',
    cc_base_url: 'https://staging.0fa9ad50.pincubator.tech',
    cid_base_url: 'https://accounts.f67a9d15.pincubator.tech',
    collection_base_url: 'https://www.claas-online.com:445/fanshop',
    et_base_url_ar: 'https://www.claas-online.com:445/etshop_ar',
    et_base_url_at: 'https://www.claas-online.com:445/etshop_at',
    et_base_url_au: 'https://www.claas-online.com:445/etshop_au',
    et_base_url_ch: 'https://www.claas-online.com:445/etshop_ch',
    et_base_url_cz: 'https://www.claas-online.com:445/etshop_cz',
    et_base_url_dk: 'https://www.claas-online.com:445/etshop_dk',
    et_base_url_ee: 'https://www.claas-online.com:445/etshop_ee',
    et_base_url_fr: 'https://www.claas-online.com:445/etshop_fr',
    et_base_url_lt: 'https://www.claas-online.com:445/etshop_lt',
    et_base_url_lv: 'https://www.claas-online.com:445/etshop_lv',
    et_base_url_nl: 'https://www.claas-online.com:445/etshop_nl',
    et_base_url_nz: 'https://www.claas-online.com:445/etshop_nz',
    et_base_url_pl: 'https://www.claas-online.com:445/etshop_pl',
    et_base_url_ro: 'https://www.claas-online.com:445/etshop_ro',
    et_base_url_sk: 'https://www.claas-online.com:445/etshop_sk',
    et_base_url_uk: 'https://www.claas-online.com:445/etshop_uk',
    et_base_url: 'https://www.claas-online.com:445/etshop',
    fcr_base_url: 'https://projekty.mda.pl/fcr/',
    fcu_base_url: 'https://www.claas-online.com:445/fcu',
    ls_base_url: 'https://www.claas-online.com:445/smp',
    mp_doi_base_url:
      'https://api.accounts.f67a9d15.pincubator.tech/marketing-doi',
    mps_base_url:
      'https://api.accounts.f67a9d15.pincubator.tech/marketing-permission',
    partsdoc_base_url: 'https://partsdoc-mobile-registered.claas.com',
    registration_service_base_url:
      'https://api.accounts.f67a9d15.pincubator.tech', // /v3/registrations',
    storage_base_url: 'https://cliddevi18n.blob.core.windows.net/translations/',
    user_api_base_url: 'https://user-api.accounts.f67a9d15.pincubator.tech',
    claas_connect_farm: 'https://sb-farm.93c13274.pincubator.tech/',
    claas_connect_farm_app: 'https://sb-farm.93c13274.pincubator.tech/'
  },
  client_ids: {
    auth_collection_id: 'UNKPDIK4wgWFvcC42XCBXHQ6sG9PASSq',
    auth_connect_3_id: 'br2wkbbm8Ujw2xD9o8CZPI080MVjWr7w',
    auth_connect_3_app_id: 'n340sGRSUmfMwQhNs5B1KOHbGWo07za8',
    auth_et_id_ar: 'eg2hcismLYJIWgZ9O0wHNXxZpfjzF6wf',
    auth_et_id_at: '6M9DWaLW0YCMdyq6ncy0IELzHviToBiX',
    auth_et_id_au: 'vbtuqtAOvHHJbD4XNnl8b8lYTCFtcr9f',
    auth_et_id_ch: 'jNJa097aa7WzcPQ8JFwQsQ0RGleFBf9Z',
    auth_et_id_cz: 'cOYwGOaNdRXkJNHnPppERxlFoKPWXvpX',
    auth_et_id_dk: 'DFP5Yh2Gz8I9LVeosoNScZShibw3V8fH',
    auth_et_id_ee: 'fr3QJUrYockr1bvApdYJrarJmVP3XFtM',
    auth_et_id_fr: 'iAysuSj70VHhTAbSrWhZAOs6NRU7ioko',
    auth_et_id_lt: 'HYWLEexJw1UcmCMOWdwxb3UMOiM6Vpx5',
    auth_et_id_lv: 'RsD3Lm6uiMJDC5JNekg37mfPYSzRJEh3',
    auth_et_id_nl: '5vA1JUVdWQG320YqRvsaRABD25DsWGE2',
    auth_et_id_nz: 'IyaYhYpTbFN0hEb4G64NmZ8uQj3t9CKx',
    auth_et_id_pl: 'okvxNon88NESWOpU1LmZqthbtCRyIFOL',
    auth_et_id_ro: '7i1Kl3fX6HKCMbDrL0KrBAzAlTgRZkDd',
    auth_et_id_sk: 'YFWy1lzlMpktO32q9bihUw19HFNBiEyb',
    auth_et_id_uk: 'N5UGKzcuhlPpFHWT8USpz5eBM7m7IfIe',
    auth_et_id: 'Sao9Ggz3Hn3KWpj6JqyuuAxCWhOwsthx',
    auth_fcr_id: 'SWJbZhbdfjSqhpeml0F6A767AZICwi1x',
    auth_fcu_id: 'SWIQHftWXdNPsHYsjwAZ6Qi1FyBcLrO6',
    auth_iwc_client_id: 'UGdntbvhOaWdFAvW3vysNXiK5EE5HlSH',
    auth_ls_id: 'qoNaDjBPnojevdR0jviUD7ZRDdSINr9c',
    auth_partsdoc_id: 'QvskCmBK3byUppUhVf8V6c5pD8AMFMwv',
    claas_connect_farm: '1VLdTg6YGQrtemCXI1ojIlm3W2WD1n9f',
    claas_connect_farm_app: 'ETVvmmsGTzrd2WJvFBZeDKv4U7J3p5fy'
  },
  general: {
    address_types: ['home'],
    countries: [
      'ar',
      'at',
      'au',
      'ca',
      'ch',
      'cz',
      'be',
      'de',
      'ee',
      'es',
      'dk',
      'fi',
      'fr',
      'gb',
      'hr',
      'it',
      'ie',
      'nl',
      'lu',
      'lv',
      'lt',
      'no',
      'nz',
      'ro',
      'pl',
      'pt',
      'py',
      'se',
      'si',
      'sk',
      'ua',
      'us',
      'uy'
    ],
    countriesDetails: [
      {
        id: 'ar',
        label: 'country.argentina',
        img: '/assets/images/flags/de.svg',
        code: '+54',
        iso: 'arg'
      },
      {
        id: 'at',
        label: 'country.austria',
        img: '/assets/images/flags/de.svg',
        code: '+43',
        iso: 'aut'
      },
      {
        id: 'au',
        label: 'country.australia',
        img: '/assets/images/flags/de.svg',
        code: '+61',
        iso: 'aus'
      },
      {
        id: 'be',
        label: 'country.belgium',
        img: '/assets/images/flags/be.svg',
        code: '+32',
        iso: 'bel'
      },
      {
        id: 'ch',
        label: 'country.switzerland',
        img: '/assets/images/flags/ch.svg',
        code: '+41',
        iso: 'che'
      },
      {
        id: 'ca',
        label: 'country.canada',
        img: '/assets/images/flags/ca.svg',
        code: '+1',
        iso: 'can'
      },
      {
        id: 'cz',
        label: 'country.czech',
        img: '/assets/images/flags/cz.svg',
        code: '+420',
        iso: 'cze'
      },
      {
        id: 'de',
        label: 'country.germany',
        img: '/assets/images/flags/de.svg',
        code: '+49',
        iso: 'deu'
      },
      {
        id: 'dk',
        label: 'country.denmark',
        img: '/assets/images/flags/dk.svg',
        code: '+45',
        iso: 'dnk'
      },
      {
        id: 'es',
        label: 'country.spain',
        img: '/assets/images/flags/pl.svg',
        code: '+34',
        iso: 'esp'
      },
      {
        id: 'ee',
        label: 'country.estonia',
        img: '/assets/images/flags/et.svg',
        code: '+372',
        iso: 'est'
      },
      {
        id: 'gb',
        label: 'country.unitedkingdom',
        img: '/assets/images/flags/no.svg',
        code: '+44',
        iso: 'gbr'
      },
      {
        id: 'hr',
        label: 'country.croatia',
        img: '/assets/images/flags/hr.svg',
        code: '+385',
        iso: 'hrv'
      },
      {
        id: 'lt',
        label: 'country.lithuania',
        img: '/assets/images/flags/lt.svg',
        code: '+370',
        iso: 'ltu'
      },
      {
        id: 'lv',
        label: 'country.latvia',
        img: '/assets/images/flags/lv.svg',
        code: '+371',
        iso: 'lva'
      },
      {
        id: 'lu',
        label: 'country.luxembourg',
        img: '/assets/images/flags/lu.svg',
        code: '+352',
        iso: 'lux'
      },
      {
        id: 'fi',
        label: 'country.finland',
        img: '/assets/images/flags/fr.svg',
        code: '+358',
        iso: 'fin'
      },
      {
        id: 'fr',
        label: 'country.france',
        img: '/assets/images/flags/fr.svg',
        code: '+33',
        iso: 'fra'
      },
      {
        id: 'ie',
        label: 'country.ireland',
        img: '/assets/images/flags/ie.svg',
        code: '+353',
        iso: 'irl'
      },
      {
        id: 'it',
        label: 'country.italy',
        img: '/assets/images/flags/fr.svg',
        code: '+39',
        iso: 'ita'
      },
      {
        id: 'nl',
        label: 'country.netherlands',
        img: '/assets/images/flags/nl.svg',
        code: '+31',
        iso: 'nld'
      },
      {
        id: 'no',
        label: 'country.norway',
        img: '/assets/images/flags/no.svg',
        code: '+47',
        iso: 'nor'
      },
      {
        id: 'nz',
        label: 'country.newzealand',
        img: '/assets/images/flags/nz.svg',
        code: '+64',
        iso: 'nzl'
      },
      {
        id: 'pl',
        label: 'country.poland',
        img: '/assets/images/flags/pl.svg',
        code: '+48',
        iso: 'pol'
      },
      {
        id: 'pt',
        label: 'country.portugal',
        img: '/assets/images/flags/pl.svg',
        code: '+351',
        iso: 'prt'
      },
      {
        id: 'py',
        label: 'country.paraguay',
        img: '/assets/images/flags/py.svg',
        code: '+595',
        iso: 'pry'
      },
      {
        id: 'ro',
        label: 'country.romania',
        img: '/assets/images/flags/ro.svg',
        code: '+40',
        iso: 'rou'
      },
      {
        id: 'se',
        label: 'country.sweden',
        img: '/assets/images/flags/se.svg',
        code: '+46',
        iso: 'swe'
      },
      {
        id: 'si',
        label: 'country.slovenia',
        img: '/assets/images/flags/si.svg',
        code: '+386',
        iso: 'svn'
      },
      {
        id: 'sk',
        label: 'country.slovakia',
        img: '/assets/images/flags/sk.svg',
        code: '+421',
        iso: 'svk'
      },
      {
        id: 'ua',
        label: 'country.ukraine',
        img: '/assets/images/flags/ua.svg',
        code: '+380',
        iso: 'ukr'
      },
      {
        id: 'us',
        label: 'country.usa',
        img: '/assets/images/flags/us.svg',
        code: '+1',
        iso: 'usa'
      },
      {
        id: 'uy',
        label: 'country.uruguay',
        img: '/assets/images/flags/uy.svg',
        code: '+598',
        iso: 'ury'
      }
    ],
    statesDetails: {
      us: [
        { id: 'al', label: 'registration.formfield.select.states.us.al' },
        { id: 'ak', label: 'registration.formfield.select.states.us.ak' },
        { id: 'az', label: 'registration.formfield.select.states.us.az' },
        { id: 'ar', label: 'registration.formfield.select.states.us.ar' },
        { id: 'ca', label: 'registration.formfield.select.states.us.ca' },
        { id: 'co', label: 'registration.formfield.select.states.us.co' },
        { id: 'ct', label: 'registration.formfield.select.states.us.ct' },
        { id: 'de', label: 'registration.formfield.select.states.us.de' },
        { id: 'dc', label: 'registration.formfield.select.states.us.dc' },
        { id: 'fl', label: 'registration.formfield.select.states.us.fl' },
        { id: 'ga', label: 'registration.formfield.select.states.us.ga' },
        { id: 'hi', label: 'registration.formfield.select.states.us.hi' },
        { id: 'id', label: 'registration.formfield.select.states.us.id' },
        { id: 'il', label: 'registration.formfield.select.states.us.il' },
        { id: 'in', label: 'registration.formfield.select.states.us.in' },
        { id: 'ia', label: 'registration.formfield.select.states.us.ia' },
        { id: 'ks', label: 'registration.formfield.select.states.us.ks' },
        { id: 'ky', label: 'registration.formfield.select.states.us.ky' },
        { id: 'la', label: 'registration.formfield.select.states.us.la' },
        { id: 'me', label: 'registration.formfield.select.states.us.me' },
        { id: 'md', label: 'registration.formfield.select.states.us.md' },
        { id: 'ma', label: 'registration.formfield.select.states.us.ma' },
        { id: 'mi', label: 'registration.formfield.select.states.us.mi' },
        { id: 'mn', label: 'registration.formfield.select.states.us.mn' },
        { id: 'ms', label: 'registration.formfield.select.states.us.ms' },
        { id: 'mo', label: 'registration.formfield.select.states.us.mo' },
        { id: 'mt', label: 'registration.formfield.select.states.us.mt' },
        { id: 'ne', label: 'registration.formfield.select.states.us.ne' },
        { id: 'nv', label: 'registration.formfield.select.states.us.nv' },
        { id: 'nh', label: 'registration.formfield.select.states.us.nh' },
        { id: 'nj', label: 'registration.formfield.select.states.us.nj' },
        { id: 'nm', label: 'registration.formfield.select.states.us.nm' },
        { id: 'ny', label: 'registration.formfield.select.states.us.ny' },
        { id: 'nc', label: 'registration.formfield.select.states.us.nc' },
        { id: 'nd', label: 'registration.formfield.select.states.us.nd' },
        { id: 'oh', label: 'registration.formfield.select.states.us.oh' },
        { id: 'ok', label: 'registration.formfield.select.states.us.ok' },
        { id: 'or', label: 'registration.formfield.select.states.us.or' },
        { id: 'pa', label: 'registration.formfield.select.states.us.pa' },
        { id: 'ri', label: 'registration.formfield.select.states.us.ri' },
        { id: 'sc', label: 'registration.formfield.select.states.us.sc' },
        { id: 'sd', label: 'registration.formfield.select.states.us.sd' },
        { id: 'tn', label: 'registration.formfield.select.states.us.tn' },
        { id: 'tx', label: 'registration.formfield.select.states.us.tx' },
        { id: 'ut', label: 'registration.formfield.select.states.us.ut' },
        { id: 'vt', label: 'registration.formfield.select.states.us.vt' },
        { id: 'va', label: 'registration.formfield.select.states.us.va' },
        { id: 'wa', label: 'registration.formfield.select.states.us.wa' },
        { id: 'wv', label: 'registration.formfield.select.states.us.wv' },
        { id: 'wi', label: 'registration.formfield.select.states.us.wi' },
        { id: 'wy', label: 'registration.formfield.select.states.us.wy' }
      ],
      ca: [
        { id: 'nl', label: 'registration.formfield.select.states.ca.nl' },
        { id: 'pe', label: 'registration.formfield.select.states.ca.pe' },
        { id: 'ns', label: 'registration.formfield.select.states.ca.ns' },
        { id: 'nb', label: 'registration.formfield.select.states.ca.nb' },
        { id: 'qc', label: 'registration.formfield.select.states.ca.qc' },
        { id: 'on', label: 'registration.formfield.select.states.ca.on' },
        { id: 'mb', label: 'registration.formfield.select.states.ca.mb' },
        { id: 'sk', label: 'registration.formfield.select.states.ca.sk' },
        { id: 'ab', label: 'registration.formfield.select.states.ca.ab' },
        { id: 'bc', label: 'registration.formfield.select.states.ca.bc' },
        { id: 'yt', label: 'registration.formfield.select.states.ca.yt' },
        { id: 'nu', label: 'registration.formfield.select.states.ca.nu' },
        { id: 'nt', label: 'registration.formfield.select.states.ca.nt' }
      ],
      au: [
        { id: 'act', label: 'registration.formfield.select.states.au.act' },
        { id: 'nsw', label: 'registration.formfield.select.states.au.nsw' },
        { id: 'nt', label: 'registration.formfield.select.states.au.nt' },
        { id: 'qld', label: 'registration.formfield.select.states.au.qld' },
        { id: 'sa', label: 'registration.formfield.select.states.au.sa' },
        { id: 'tas', label: 'registration.formfield.select.states.au.tas' },
        { id: 'vic', label: 'registration.formfield.select.state.sau.vic' },
        { id: 'wa', label: 'registration.formfield.select.states.au.wa' }
      ],
      ar: [
        { id: '02', label: 'registration.formfield.select.states.ar.02' },
        { id: '06', label: 'registration.formfield.select.states.ar.06' },
        { id: '10', label: 'registration.formfield.select.states.ar.10' },
        { id: '14', label: 'registration.formfield.select.states.ar.14' },
        { id: '18', label: 'registration.formfield.select.states.ar.18' },
        { id: '22', label: 'registration.formfield.select.states.ar.22' },
        { id: '26', label: 'registration.formfield.select.states.ar.26' },
        { id: '30', label: 'registration.formfield.select.states.ar.30' },
        { id: '34', label: 'registration.formfield.select.states.ar.34' },
        { id: '38', label: 'registration.formfield.select.states.ar.38' },
        { id: '42', label: 'registration.formfield.select.states.ar.42' },
        { id: '46', label: 'registration.formfield.select.states.ar.46' },
        { id: '50', label: 'registration.formfield.select.states.ar.50' },
        { id: '54', label: 'registration.formfield.select.states.ar.54' },
        { id: '58', label: 'registration.formfield.select.states.ar.58' },
        { id: '62', label: 'registration.formfield.select.states.ar.62' },
        { id: '66', label: 'registration.formfield.select.states.ar.66' },
        { id: '70', label: 'registration.formfield.select.states.ar.70' },
        { id: '74', label: 'registration.formfield.select.states.ar.74' },
        { id: '78', label: 'registration.formfield.select.states.ar.78' },
        { id: '82', label: 'registration.formfield.select.states.ar.82' },
        { id: '86', label: 'registration.formfield.select.states.ar.86' },
        { id: '90', label: 'registration.formfield.select.states.ar.90' },
        { id: '94', label: 'registration.formfield.select.states.ar.94' }
      ],
      ua: [
        { id: '01', label: 'registration.formfield.select.states.ua.01' },
        { id: '05', label: 'registration.formfield.select.states.ua.05' },
        { id: '07', label: 'registration.formfield.select.states.ua.07' },
        { id: '12', label: 'registration.formfield.select.states.ua.12' },
        { id: '14', label: 'registration.formfield.select.states.ua.14' },
        { id: '18', label: 'registration.formfield.select.states.ua.18' },
        { id: '21', label: 'registration.formfield.select.states.ua.21' },
        { id: '23', label: 'registration.formfield.select.states.ua.23' },
        { id: '26', label: 'registration.formfield.select.states.ua.26' },
        { id: '32', label: 'registration.formfield.select.states.ua.32' },
        { id: '35', label: 'registration.formfield.select.states.ua.35' },
        { id: '44', label: 'registration.formfield.select.states.ua.44' },
        { id: '46', label: 'registration.formfield.select.states.ua.46' },
        { id: '48', label: 'registration.formfield.select.states.ua.48' },
        { id: '51', label: 'registration.formfield.select.states.ua.51' },
        { id: '53', label: 'registration.formfield.select.states.ua.53' },
        { id: '56', label: 'registration.formfield.select.states.ua.56' },
        { id: '59', label: 'registration.formfield.select.states.ua.59' },
        { id: '61', label: 'registration.formfield.select.states.ua.61' },
        { id: '63', label: 'registration.formfield.select.states.ua.63' },
        { id: '65', label: 'registration.formfield.select.states.ua.65' },
        { id: '68', label: 'registration.formfield.select.states.ua.68' },
        { id: '71', label: 'registration.formfield.select.states.ua.71' },
        { id: '73', label: 'registration.formfield.select.states.ua.73' },
        { id: '74', label: 'registration.formfield.select.states.ua.74' },
        { id: '80', label: 'registration.formfield.select.states.ua.80' },
        { id: '85', label: 'registration.formfield.select.states.ua.85' }
      ],
      it: [
        { id: '001', label: 'registration.formfield.select.states.it.001' },
        { id: '002', label: 'registration.formfield.select.states.it.002' },
        { id: '003', label: 'registration.formfield.select.states.it.003' },
        { id: '004', label: 'registration.formfield.select.states.it.004' },
        { id: '005', label: 'registration.formfield.select.states.it.005' },
        { id: '006', label: 'registration.formfield.select.states.it.006' },
        { id: '007', label: 'registration.formfield.select.states.it.007' },
        { id: '008', label: 'registration.formfield.select.states.it.008' },
        { id: '009', label: 'registration.formfield.select.states.it.009' },
        { id: '010', label: 'registration.formfield.select.states.it.010' },
        { id: '011', label: 'registration.formfield.select.states.it.011' },
        { id: '012', label: 'registration.formfield.select.states.it.012' },
        { id: '013', label: 'registration.formfield.select.states.it.013' },
        { id: '014', label: 'registration.formfield.select.states.it.014' },
        { id: '015', label: 'registration.formfield.select.states.it.015' },
        { id: '016', label: 'registration.formfield.select.states.it.016' },
        { id: '017', label: 'registration.formfield.select.states.it.017' },
        { id: '018', label: 'registration.formfield.select.states.it.018' },
        { id: '019', label: 'registration.formfield.select.states.it.019' },
        { id: '020', label: 'registration.formfield.select.states.it.020' },
        { id: '021', label: 'registration.formfield.select.states.it.021' },
        { id: '022', label: 'registration.formfield.select.states.it.022' },
        { id: '023', label: 'registration.formfield.select.states.it.023' },
        { id: '024', label: 'registration.formfield.select.states.it.024' },
        { id: '025', label: 'registration.formfield.select.states.it.025' },
        { id: '026', label: 'registration.formfield.select.states.it.026' },
        { id: '027', label: 'registration.formfield.select.states.it.027' },
        { id: '028', label: 'registration.formfield.select.states.it.028' },
        { id: '029', label: 'registration.formfield.select.states.it.029' },
        { id: '030', label: 'registration.formfield.select.states.it.030' },
        { id: '031', label: 'registration.formfield.select.states.it.031' },
        { id: '032', label: 'registration.formfield.select.states.it.032' },
        { id: '033', label: 'registration.formfield.select.states.it.033' },
        { id: '034', label: 'registration.formfield.select.states.it.034' },
        { id: '035', label: 'registration.formfield.select.states.it.035' },
        { id: '036', label: 'registration.formfield.select.states.it.036' },
        { id: '037', label: 'registration.formfield.select.states.it.037' },
        { id: '038', label: 'registration.formfield.select.states.it.038' },
        { id: '039', label: 'registration.formfield.select.states.it.039' },
        { id: '040', label: 'registration.formfield.select.states.it.040' },
        { id: '041', label: 'registration.formfield.select.states.it.041' },
        { id: '042', label: 'registration.formfield.select.states.it.042' },
        { id: '043', label: 'registration.formfield.select.states.it.043' },
        { id: '044', label: 'registration.formfield.select.states.it.044' },
        { id: '045', label: 'registration.formfield.select.states.it.045' },
        { id: '046', label: 'registration.formfield.select.states.it.046' },
        { id: '047', label: 'registration.formfield.select.states.it.047' },
        { id: '048', label: 'registration.formfield.select.states.it.048' },
        { id: '049', label: 'registration.formfield.select.states.it.049' },
        { id: '050', label: 'registration.formfield.select.states.it.050' },
        { id: '051', label: 'registration.formfield.select.states.it.051' },
        { id: '052', label: 'registration.formfield.select.states.it.052' },
        { id: '053', label: 'registration.formfield.select.states.it.053' },
        { id: '054', label: 'registration.formfield.select.states.it.054' },
        { id: '055', label: 'registration.formfield.select.states.it.055' },
        { id: '056', label: 'registration.formfield.select.states.it.056' },
        { id: '057', label: 'registration.formfield.select.states.it.057' },
        { id: '058', label: 'registration.formfield.select.states.it.058' },
        { id: '059', label: 'registration.formfield.select.states.it.059' },
        { id: '060', label: 'registration.formfield.select.states.it.060' },
        { id: '061', label: 'registration.formfield.select.states.it.061' },
        { id: '062', label: 'registration.formfield.select.states.it.062' },
        { id: '063', label: 'registration.formfield.select.states.it.063' },
        { id: '064', label: 'registration.formfield.select.states.it.064' },
        { id: '065', label: 'registration.formfield.select.states.it.065' },
        { id: '066', label: 'registration.formfield.select.states.it.066' },
        { id: '067', label: 'registration.formfield.select.states.it.067' },
        { id: '068', label: 'registration.formfield.select.states.it.068' },
        { id: '069', label: 'registration.formfield.select.states.it.069' },
        { id: '070', label: 'registration.formfield.select.states.it.070' },
        { id: '071', label: 'registration.formfield.select.states.it.071' },
        { id: '072', label: 'registration.formfield.select.states.it.072' },
        { id: '073', label: 'registration.formfield.select.states.it.073' },
        { id: '074', label: 'registration.formfield.select.states.it.074' },
        { id: '075', label: 'registration.formfield.select.states.it.075' },
        { id: '076', label: 'registration.formfield.select.states.it.076' },
        { id: '077', label: 'registration.formfield.select.states.it.077' },
        { id: '078', label: 'registration.formfield.select.states.it.078' },
        { id: '079', label: 'registration.formfield.select.states.it.079' },
        { id: '080', label: 'registration.formfield.select.states.it.080' },
        { id: '081', label: 'registration.formfield.select.states.it.081' },
        { id: '082', label: 'registration.formfield.select.states.it.082' },
        { id: '083', label: 'registration.formfield.select.states.it.083' },
        { id: '084', label: 'registration.formfield.select.states.it.084' },
        { id: '085', label: 'registration.formfield.select.states.it.085' },
        { id: '086', label: 'registration.formfield.select.states.it.086' },
        { id: '087', label: 'registration.formfield.select.states.it.087' },
        { id: '088', label: 'registration.formfield.select.states.it.088' },
        { id: '089', label: 'registration.formfield.select.states.it.089' },
        { id: '090', label: 'registration.formfield.select.states.it.090' },
        { id: '091', label: 'registration.formfield.select.states.it.091' },
        { id: '092', label: 'registration.formfield.select.states.it.092' },
        { id: '093', label: 'registration.formfield.select.states.it.093' },
        { id: '094', label: 'registration.formfield.select.states.it.094' },
        { id: '095', label: 'registration.formfield.select.states.it.095' },
        { id: '096', label: 'registration.formfield.select.states.it.096' },
        { id: '097', label: 'registration.formfield.select.states.it.097' },
        { id: '098', label: 'registration.formfield.select.states.it.098' },
        { id: '099', label: 'registration.formfield.select.states.it.099' },
        { id: '100', label: 'registration.formfield.select.states.it.100' },
        { id: '101', label: 'registration.formfield.select.states.it.101' },
        { id: '102', label: 'registration.formfield.select.states.it.102' },
        { id: '103', label: 'registration.formfield.select.states.it.103' },
        { id: '108', label: 'registration.formfield.select.states.it.108' },
        { id: '109', label: 'registration.formfield.select.states.it.109' },
        { id: '110', label: 'registration.formfield.select.states.it.110' },
        { id: '111', label: 'registration.formfield.select.states.it.111' }
      ]
    },
    territoriesDetails: {
      gb: [
        { id: 'kh', label: 'registration.formfield.select.territories.gb.kh' },
        { id: 'la', label: 'registration.formfield.select.territories.gb.la' },
        {
          id: 'lbc',
          label: 'registration.formfield.select.territories.gb.lbc'
        },
        { id: 'lc', label: 'registration.formfield.select.territories.gb.lc' },
        { id: 'le', label: 'registration.formfield.select.territories.gb.le' },
        { id: 'li', label: 'registration.formfield.select.territories.gb.li' },
        { id: 'lk', label: 'registration.formfield.select.territories.gb.lk' },
        { id: 'lo', label: 'registration.formfield.select.territories.gb.lo' },
        { id: 'lt', label: 'registration.formfield.select.territories.gb.lt' },
        { id: 'lu', label: 'registration.formfield.select.territories.gb.lu' },
        {
          id: 'mea',
          label: 'registration.formfield.select.territories.gb.mea'
        },
        { id: 'mg', label: 'registration.formfield.select.territories.gb.mg' },
        { id: 'mi', label: 'registration.formfield.select.territories.gb.mi' },
        { id: 'mk', label: 'registration.formfield.select.territories.gb.mk' },
        {
          id: 'mon',
          label: 'registration.formfield.select.territories.gb.mon'
        },
        { id: 'mr', label: 'registration.formfield.select.territories.gb.mr' },
        {
          id: 'mul',
          label: 'registration.formfield.select.territories.gb.mul'
        },
        { id: 'my', label: 'registration.formfield.select.territories.gb.my' },
        { id: 'nc', label: 'registration.formfield.select.territories.gb.nc' },
        { id: 'ne', label: 'registration.formfield.select.territories.gb.ne' },
        { id: 'nh', label: 'registration.formfield.select.territories.gb.nh' },
        { id: 'nk', label: 'registration.formfield.select.territories.gb.nk' },
        { id: 'nl', label: 'registration.formfield.select.territories.gb.nl' },
        {
          id: 'nmd',
          label: 'registration.formfield.select.territories.gb.nmd'
        },
        { id: 'ns', label: 'registration.formfield.select.territories.gb.ns' },
        { id: 'nt', label: 'registration.formfield.select.territories.gb.nt' },
        { id: 'nu', label: 'registration.formfield.select.territories.gb.nu' },
        { id: 'or', label: 'registration.formfield.select.territories.gb.or' },
        { id: 'ox', label: 'registration.formfield.select.territories.gb.ox' },
        { id: 'pb', label: 'registration.formfield.select.territories.gb.pb' },
        { id: 'pm', label: 'registration.formfield.select.territories.gb.pm' },
        { id: 'po', label: 'registration.formfield.select.territories.gb.po' },
        { id: 'py', label: 'registration.formfield.select.territories.gb.py' },
        { id: 'rd', label: 'registration.formfield.select.territories.gb.rd' },
        { id: 're', label: 'registration.formfield.select.territories.gb.re' },
        { id: 'rm', label: 'registration.formfield.select.territories.gb.rm' },
        { id: 'ru', label: 'registration.formfield.select.territories.gb.ru' },
        { id: 'sc', label: 'registration.formfield.select.territories.gb.sc' },
        { id: 'sd', label: 'registration.formfield.select.territories.gb.sd' },
        { id: 'se', label: 'registration.formfield.select.territories.gb.se' },
        { id: 'sg', label: 'registration.formfield.select.territories.gb.sg' },
        { id: 'sh', label: 'registration.formfield.select.territories.gb.sh' },
        { id: 'sk', label: 'registration.formfield.select.territories.gb.sk' },
        { id: 'so', label: 'registration.formfield.select.territories.gb.so' },
        { id: 'sp', label: 'registration.formfield.select.territories.gb.sp' },
        { id: 'ss', label: 'registration.formfield.select.territories.gb.ss' },
        { id: 'sl', label: 'registration.formfield.select.territories.gb.sl' },
        { id: 'sw', label: 'registration.formfield.select.territories.gb.sw' },
        { id: 'sy', label: 'registration.formfield.select.territories.gb.sy' },
        { id: 'ta', label: 'registration.formfield.select.territories.gb.ta' },
        { id: 'th', label: 'registration.formfield.select.territories.gb.th' },
        { id: 'to', label: 'registration.formfield.select.territories.gb.to' },
        { id: 'tw', label: 'registration.formfield.select.territories.gb.tw' },
        { id: 'wa', label: 'registration.formfield.select.territories.gb.wa' },
        { id: 'wc', label: 'registration.formfield.select.territories.gb.wc' },
        { id: 'wd', label: 'registration.formfield.select.territories.gb.wd' },
        { id: 'wg', label: 'registration.formfield.select.territories.gb.wg' },
        { id: 'wi', label: 'registration.formfield.select.territories.gb.wi' },
        { id: 'wl', label: 'registration.formfield.select.territories.gb.wl' },
        { id: 'wm', label: 'registration.formfield.select.territories.gb.wm' },
        { id: 'wo', label: 'registration.formfield.select.territories.gb.wo' },
        { id: 'wr', label: 'registration.formfield.select.territories.gb.wr' },
        { id: 'wt', label: 'registration.formfield.select.territories.gb.wt' },
        { id: 'ye', label: 'registration.formfield.select.territories.gb.ye' },
        { id: 'yk', label: 'registration.formfield.select.territories.gb.yk' },
        { id: 'yn', label: 'registration.formfield.select.territories.gb.yn' },
        { id: 'ys', label: 'registration.formfield.select.territories.gb.ys' },
        { id: 'yw', label: 'registration.formfield.select.territories.gb.yw' }
      ],
      ie: [
        { id: '01', label: 'registration.formfield.select.territories.ie.01' },
        { id: '021', label: 'registration.formfield.select.territories.ie.21' },
        {
          id: '022',
          label: 'registration.formfield.select.territories.ie.022'
        },
        {
          id: '023',
          label: 'registration.formfield.select.territories.ie.023'
        },
        {
          id: '024',
          label: 'registration.formfield.select.territories.ie.024'
        },
        { id: '03', label: 'registration.formfield.select.territories.ie.03' },
        { id: '04', label: 'registration.formfield.select.territories.ie.04' },
        { id: '05', label: 'registration.formfield.select.territories.ie.05' },
        { id: '06', label: 'registration.formfield.select.territories.ie.06' },
        { id: '07', label: 'registration.formfield.select.territories.ie.07' },
        { id: '08', label: 'registration.formfield.select.territories.ie.08' },
        { id: '09', label: 'registration.formfield.select.territories.ie.09' },
        { id: '10', label: 'registration.formfield.select.territories.ie.10' },
        { id: '11', label: 'registration.formfield.select.territories.ie.11' },
        { id: '12', label: 'registration.formfield.select.territories.ie.12' },
        { id: '13', label: 'registration.formfield.select.territories.ie.13' },
        {
          id: '141',
          label: 'registration.formfield.select.territories.ie.141'
        },
        {
          id: '142',
          label: 'registration.formfield.select.territories.ie.142'
        },
        { id: '15', label: 'registration.formfield.select.territories.ie.15' },
        { id: '16', label: 'registration.formfield.select.territories.ie.16' },
        { id: '17', label: 'registration.formfield.select.territories.ie.17' },
        { id: '18', label: 'registration.formfield.select.territories.ie.18' },
        {
          id: '191',
          label: 'registration.formfield.select.territories.ie.191'
        },
        {
          id: '192',
          label: 'registration.formfield.select.territories.ie.192'
        },
        { id: '20', label: 'registration.formfield.select.territories.ie.20' },
        { id: '21', label: 'registration.formfield.select.territories.ie.21' },
        { id: '22', label: 'registration.formfield.select.territories.ie.22' },
        { id: '23', label: 'registration.formfield.select.territories.ie.23' },
        { id: '24', label: 'registration.formfield.select.territories.ie.24' },
        { id: '25', label: 'registration.formfield.select.territories.ie.25' },
        { id: '26', label: 'registration.formfield.select.territories.ie.26' }
      ],
      ro: [
        { id: '01', label: 'registration.formfield.select.territories.ro.01' },
        { id: '02', label: 'registration.formfield.select.territories.ro.02' },
        { id: '03', label: 'registration.formfield.select.territories.ro.03' },
        { id: '04', label: 'registration.formfield.select.territories.ro.04' },
        { id: '05', label: 'registration.formfield.select.territories.ro.05' },
        { id: '06', label: 'registration.formfield.select.territories.ro.06' },
        { id: '07', label: 'registration.formfield.select.territories.ro.07' },
        { id: '08', label: 'registration.formfield.select.territories.ro.08' },
        { id: '09', label: 'registration.formfield.select.territories.ro.09' },
        { id: '10', label: 'registration.formfield.select.territories.ro.10' },
        { id: '11', label: 'registration.formfield.select.territories.ro.11' },
        { id: '12', label: 'registration.formfield.select.territories.ro.12' },
        { id: '13', label: 'registration.formfield.select.territories.ro.13' },
        { id: '14', label: 'registration.formfield.select.territories.ro.14' },
        { id: '15', label: 'registration.formfield.select.territories.ro.15' },
        { id: '16', label: 'registration.formfield.select.territories.ro.16' },
        { id: '17', label: 'registration.formfield.select.territories.ro.17' },
        { id: '18', label: 'registration.formfield.select.territories.ro.18' },
        { id: '19', label: 'registration.formfield.select.territories.ro.19' },
        { id: '20', label: 'registration.formfield.select.territories.ro.20' },
        { id: '21', label: 'registration.formfield.select.territories.ro.21' },
        { id: '22', label: 'registration.formfield.select.territories.ro.22' },
        { id: '23', label: 'registration.formfield.select.territories.ro.23' },
        { id: '24', label: 'registration.formfield.select.territories.ro.24' },
        { id: '25', label: 'registration.formfield.select.territories.ro.25' },
        { id: '26', label: 'registration.formfield.select.territories.ro.26' },
        { id: '27', label: 'registration.formfield.select.territories.ro.27' },
        { id: '28', label: 'registration.formfield.select.territories.ro.28' },
        { id: '29', label: 'registration.formfield.select.territories.ro.29' },
        { id: '30', label: 'registration.formfield.select.territories.ro.30' },
        { id: '31', label: 'registration.formfield.select.territories.ro.31' },
        { id: '32', label: 'registration.formfield.select.territories.ro.32' },
        { id: '33', label: 'registration.formfield.select.territories.ro.33' },
        { id: '34', label: 'registration.formfield.select.territories.ro.34' },
        { id: '35', label: 'registration.formfield.select.territories.ro.35' },
        { id: '36', label: 'registration.formfield.select.territories.ro.36' },
        { id: '37', label: 'registration.formfield.select.territories.ro.37' },
        { id: '38', label: 'registration.formfield.select.territories.ro.38' },
        { id: '39', label: 'registration.formfield.select.territories.ro.39' },
        { id: '40', label: 'registration.formfield.select.territories.ro.40' },
        { id: '51', label: 'registration.formfield.select.territories.ro.51' },
        { id: '52', label: 'registration.formfield.select.territories.ro.52' }
      ]
    },
    email_pattern:
      "[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+[.]+[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+",
    languages: [
      'cs',
      'da',
      'de',
      'es',
      'et',
      'en-gb',
      'en-us',
      'fi',
      'fr',
      'hr',
      'it',
      'lv',
      'lt',
      'nl',
      'no',
      'ro',
      'pl',
      'pt',
      'sl',
      'sk',
      'sv',
      'uk'
    ],
    name_length: 2,
    name_pattern: '[ÄÖÜÙÚÀÝÉÈäöüùúèéáàßA-Za-z -]*',
    password_length: 8,
    password_pattern: "[A-Za-z0-9]*[!#$%&'*+/=?^_`{|}~-]+[A-Za-z0-9]*",
    phone_pattern: '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s.\0-9]*$',
    phone_types: ['phone', 'mobile'],
    salutations: ['mr', 'mrs'],
    unitSystems: [
      { value: 'METRIC' },
      { value: 'IMPERIAL' },
      { value: 'CUSTOMARY' }
    ]
  },
  login_urls: {
    cc_login_url: 'https://staging.0fa9ad50.pincubator.tech?login=true',
    collection_login_url:
      'https://login.f67a9d15.pincubator.tech/samlp/UNKPDIK4wgWFvcC42XCBXHQ6sG9PASSq',
    et_login_url_ar:
      'https://login.f67a9d15.pincubator.tech/samlp/eg2hcismLYJIWgZ9O0wHNXxZpfjzF6wf',
    et_login_url_at:
      'https://login.f67a9d15.pincubator.tech/samlp/6M9DWaLW0YCMdyq6ncy0IELzHviToBiX',
    et_login_url_au:
      'https://login.b389c9ef.pincubator.tech/samlp/vbtuqtAOvHHJbD4XNnl8b8lYTCFtcr9f',
    et_login_url_ch:
      'https://login.f67a9d15.pincubator.tech/samlp/jNJa097aa7WzcPQ8JFwQsQ0RGleFBf9Z',
    et_login_url_cz:
      'https://login.f67a9d15.pincubator.tech/samlp/cOYwGOaNdRXkJNHnPppERxlFoKPWXvpX',
    et_login_url_dk:
      'https://login.f67a9d15.pincubator.tech/samlp/DFP5Yh2Gz8I9LVeosoNScZShibw3V8fH',
    et_login_url_ee:
      'https://login.f67a9d15.pincubator.tech/samlp/fr3QJUrYockr1bvApdYJrarJmVP3XFtM',
    et_login_url_fr:
      'https://login.f67a9d15.pincubator.tech/samlp/iAysuSj70VHhTAbSrWhZAOs6NRU7ioko',
    et_login_url_lt:
      'https://login.f67a9d15.pincubator.tech/samlp/HYWLEexJw1UcmCMOWdwxb3UMOiM6Vpx5',
    et_login_url_lv:
      'https://login.f67a9d15.pincubator.tech/samlp/RsD3Lm6uiMJDC5JNekg37mfPYSzRJEh3',
    et_login_url_nl:
      'https://login.f67a9d15.pincubator.tech/samlp/5vA1JUVdWQG320YqRvsaRABD25DsWGE2',
    et_login_url_nz:
      'https://login.b389c9ef.pincubator.tech/samlp/IyaYhYpTbFN0hEb4G64NmZ8uQj3t9CKx',
    et_login_url_pl:
      'https://login.f67a9d15.pincubator.tech/samlp/okvxNon88NESWOpU1LmZqthbtCRyIFOL',
    et_login_url_ro:
      'https://login.f67a9d15.pincubator.tech/samlp/7i1Kl3fX6HKCMbDrL0KrBAzAlTgRZkDd',
    et_login_url_sk:
      'https://login.f67a9d15.pincubator.tech/samlp/YFWy1lzlMpktO32q9bihUw19HFNBiEyb',
    et_login_url_uk:
      'https://login.f67a9d15.pincubator.tech/samlp/N5UGKzcuhlPpFHWT8USpz5eBM7m7IfIe',
    et_login_url:
      'https://login.f67a9d15.pincubator.tech/samlp/Sao9Ggz3Hn3KWpj6JqyuuAxCWhOwsthx',
    fcu_login_url:
      'https://login.gcp01.claas-labs.de/samlp/SWIQHftWXdNPsHYsjwAZ6Qi1FyBcLrO6',
    ls_login_url:
      'https://login.gcp01.claas-labs.de/samlp/qoNaDjBPnojevdR0jviUD7ZRDdSINr9c',
    partsdoc_login_url: 'https://partsdoc-mobile-registered.claas.com/'
  },
  marketing_footnotes: {
    at: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, die zuvor genannten jeweils Mühlenwinkel 1, 3328 Harsewinkel, Deutschland, E-Mail: infoclaas@claas.com, CLAAS Regional Center Central Europe GmbH, Industriestraße 18, 2104 Spillern, Österreich, E-Mail: info-at@claas.com (hiernach auch „CLAAS“), 365 FarmNet Group KGaA mbH & Co. KG, Hausvogteiplatz  10, 10117 Berlin, Deutschland;  CLAAS E-Systems GmbH, Sommerkämpen 11, 49201 Dissen a.T.W, Deutschland',
    hr: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, gore spomenuti Mühlenwinkel 1, 3328 Harsewinkel, Njemačka, e-pošta: infoclaas@claas.com, CLAAS Regional Center Central Europe GmbH, Industriestraße 18, 2104 Spillern, Austrija, e-pošta: info-at@claas.com (u daljnjem tekstu također „CLAAS”); 365 FarmNet Group KGaA mbH & Co. KG, Hausvogteiplatz  10, 10117 Berlin, Njemačka;  CLAAS E-Systems GmbH, Sommerkämpen 11, 49201 Dissen a.T.W, Njemačka',
    si: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, na naslovu Mühlenwinkel 1, 3328 Harsewinkel, Nemčija, e-pošta: infoclaas@claas.com, CLAAS Regional Center Central Europe GmbH, Industriestraße 18, 2104 Spillern, Avstrija, e-pošta: info-at@claas.com (v nadaljevanju tudi »CLAAS«); 365 FarmNet Group KGaA mbH & Co. KG, Hausvogteiplatz  10, 10117 Berlin, Nemčija;  CLAAS E-Systems GmbH, Sommerkämpen 11, 49201 Dissen a.T.W, Nemčija',
    ca: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, in each case Muehlenwinkel 1, 33428 Harsewinkel, Germany, CLAAS of America, Inc., 8401 South 132nd Street, Omaha, NE 68138, USA (hereinafter "CLAAS sales company")',
    de: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, jeweils Mühlenwinkel 1, 33428 Harsewinkel, Deutschland, CLAAS Vertriebsgesellschaft mbH, Benzstraße 5, 33442 Herzebrock-Clarholz, Deutschland (hiernach „CLAAS Vertriebsgesellschaft“), 365FarmNet GmbH, Hausvogteiplatz 10, 10117 Berlin, Deutschland',
    es: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, respectivamente Mühlenwinkel 1, 33428 Harsewinkel, Alemania, Claas Ibérica, S.A.U., C/ Zeus 5, 28880 Meco (Madrid), Espana, (en lo sucesivo, «sociedad comercializadora de CLAAS»)',
    fr: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, sises Mühlenwinkel 1, 33428 Harsewinkel, Allemagne, CLAAS France, Chemin des Grands Prés du Gué, 28320 Ymeray, France, (ci-après dénommées « société de distribution CLAAS ») et 365FarmNet GmbH, Hausvogteiplatz 10, 10117 Berlin, Allemagne',
    gb: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, in each case Muehlenwinkel 1, 33428 Harsewinkel, Germany, CLAAS UK Ltd., Saxham, Bury St. Edmunds, Suffolk, IP28 6QZ, United Kingdom (hereinafter “CLAAS sales company”)',
    it: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, rispettivamente Mühlenwinkel 1, 33428 Harsewinkel, Germania, CLAAS Italy S.p.A., Via Torino 9, 13100 Vercelli VC, Italy (di seguito “Società di vendita CLAAS”)',
    pl: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, adres Mühlenwinkel 1, 33428 Harsewinkel, Niemcy, E-Mail: infoclaas@claas.com; CLAAS Polska Sp.z.o.o. , ul. Świerkowa 7 , Niepruszewo,64-320 Buk, Polska, E-Mail: claas.polska@claas.com (w dalszej części jako „spółka handlowa CLAAS”); 365 FarmNet Group KGaA mbH & Co. KG, Hausvogteiplatz  10, 10117 Berlin, Niemcy; CLAAS Financial Services SAS SA Spółka Akcyjna Uproszczona Oddział w Polsce, ul. Grzybowska 78, 00-844 Warszawa, Polska; CLAAS E-Systems GmbH, Sommerkämpen 11, 49201 Dissen a.T.W, Niemcy',
    us: 'CLAAS KGaA mbH, CLAAS Global Sales GmbH, CLAAS Service and Parts GmbH, in each case Muehlenwinkel 1, 33428 Harsewinkel, Germany, E-Mail: infoclaas@claas.com, CLAAS of America, Inc., 8401 South 132nd Street, Omaha, NE 68138, USA, E-Mail: info@claas.com (hereinafter “CLAAS Salescompany”)'
  },
  production: false,
  name: 'development',
  shops_url: 'https://www.93c13274.pincubator.tech/shops',
  info_url: 'https://help.connect.claas.com/'
};
