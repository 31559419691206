import { Component, OnDestroy } from '@angular/core';
import {
  ButtonClass,
  ButtonSize,
  Icon,
  IconFontSize,
  IconSize
} from '@claas/claas-form-components';
import { ApiService } from '../shared/services/api.service';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subscription,
  forkJoin,
  of,
  take,
  tap,
  switchMap
} from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUserDetails } from '../shared/state/user';
import { filter, map } from 'rxjs/operators';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { Router } from '@angular/router';
import {
  loadAddressDetails,
  loadOrganisationRegistrationSteps,
  loadRegistrationDefaultOrganisation,
  loadUserDefaultOrganisation,
  loadUserOrganisations,
  organisationMembersNotLoaded,
  registerOrganisation,
  setCurrentOrganisation
} from '../shared/state/organisations';
import {
  selectAddressDetails,
  selectCurrentOrganisation,
  selectDefaultOrganisation,
  selectOrganisationError,
  selectOrganisations,
  selectRegisteredOrganisation,
  selectRegistrationDefaultOrganisation,
  selectRegistrationSteps
} from '../shared/state/organisations/organisations.selectors';
import { UserOrganisations } from '../shared/state/organisations/organisations.state';
import { TranslateService } from '@ngx-translate/core';
import {
  IOrganisationRegistrationDialogInput,
  OrganisationRegistrationDialogComponent
} from 'cde-fe-organization-registration-dialog';
import { OrganisationRegistrationDialogInputModel } from '../shared/models/organisationRegistrationDialogInput.model';
import { environment } from '../../environments/environment';
import {
  selectAddressAutocompleteSuggestionsData,
  selectRegistrationDataPending,
  selectRegistrationDealerData,
  selectRegistrationErrorData,
  selectRegistrationPersonalData,
  selectRegistrationPersonalDataPending,
  selectRegistrationShopData
} from '../shared/state/global/global.selectors';
import {
  selectDealerByNameSearchData,
  selectDealerFiltersData,
  selectNearestDealerSearchData,
  selectNearestDealerSearchDataPending
} from '../shared/state/dealers/dealers.selectors';
import { User } from 'cde-fe-organization-registration-dialog/lib/models/auth.model';
import { AuthService } from '../shared/services/auth.service';
import {
  IAddressAutocompleteRequest,
  IAddressDetailsRequest,
  IRegistrationDialogStep
} from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  loadDealerByNameSearchData,
  loadDealerFiltersData,
  loadNearestDealersSearchData
} from '../shared/state/dealers';
import {
  clearRegistrationData,
  loadAddressAutocompleteSuggestionsData,
  loadRegistrationDealerData,
  loadRegistrationPersonalData,
  loadRegistrationShopData,
  updateRegistrationPersonalData
} from '../shared/state/global';
import { OrganisationUpdateRequest } from '../shared/models/organisation-update-request.model';
import {
  DialogWindowComponent,
  ResponseDataFromDialog,
  passedDataToDialog
} from '../shared/components/dialog-window/dialog-window.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MarketingPermissionService } from '../shared/services/marketing-permission.service';

declare const window: any;

@Component({
  selector: 'app-organisation',
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.scss']
})
export class OrganisationsComponent implements OnDestroy {
  largeSize = CircularProgressDiameter.LARGE;
  primaryButtonClass = ButtonClass.PRIMARY;
  mediumButtonSize = ButtonSize.DEFAULT_MEDIUM;
  isHandset = false;
  addIcon: Icon = {
    iconId: '000973',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 19
  };
  organisationUpdateRequest: OrganisationUpdateRequest | undefined;
  currentUser: User | undefined = undefined;
  accessToken = '';
  registrationSteps: IRegistrationDialogStep[] | undefined = [];
  mediumIconSize = IconSize.MEDIUM;
  mediumIconFontSize = IconFontSize.MEDIUM;
  secondaryColor = ProgressColor.SECONDARY;
  organisations$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  isLoadingOrganisations$: BehaviorSubject<any> = new BehaviorSubject(true);
  private subscriptions = new Subscription();

  constructor(
    private store: Store,
    private apiService: ApiService,
    private router: Router,
    private translateService: TranslateService,
    private authService: AuthService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private marketingPermissionService: MarketingPermissionService
  ) {
    this.store.dispatch(loadOrganisationRegistrationSteps());
    this.getOrganisations();

    this.organisations$.subscribe(orgs => {
      if (orgs) {
        for (const org of orgs) {
          if (org.myRole === 'organisation.role.admin') {
            this.apiService
              .getOrganisationUpdateRequest(org.auth0Id)
              .subscribe((res: any) => {
                if (res) {
                  this.organisationUpdateRequest = res;

                  if (this.dialog.openDialogs.length === 0) {
                    this.openDialog('org-update');
                  }
                }
              });
          }
        }
      }
    });

    this.subscriptions.add(
      this.authService
        .getTokenSilently$()
        .subscribe(token => (this.accessToken = token))
    );

    this.subscriptions.add(
      this.store
        .select(selectRegistrationSteps)
        .subscribe(steps => (this.registrationSteps = steps))
    );
  }

  isHandset$(): Observable<any> {
    return this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map((state: any) => (this.isHandset = state.matches)));
  }

  getRole(role: string) {
    let r = '';
    const split = role.split('.');
    this.translateService
      .get('organization.roles.' + split.pop())
      .subscribe((text: string) => (r = text));
    return r;
  }

  getOrganisations() {
    this.subscriptions.add(
      this.store
        .select(selectUserDetails)
        .pipe(
          filter((user: any) => !!user),
          tap((user: any) => {
            this.currentUser = user;
            // in case the token is required please use "user.sub" for it
            this.store.dispatch(loadUserOrganisations({ userId: user.uuid }));

            this.store.dispatch(
              loadUserDefaultOrganisation({ userId: user.uuid })
            );

            this.subscriptions.add(
              this.store
                .select(selectOrganisations)
                .pipe(
                  filter((organisations: any) => !!organisations),
                  map((organisations: UserOrganisations) => {
                    if (Object.keys(organisations).length === 0) {
                      // the user has no registered organisation
                      this.isLoadingOrganisations$.next(false);
                    } else {
                      this.subscriptions.add(
                        this.getOrganisationsMembers$(organisations).subscribe({
                          next: (orgaMembersDetails: any[]) => {
                            {
                              organisations = this.addAdminNameProperty(
                                organisations,
                                orgaMembersDetails
                              );

                              organisations = this.addMyRoleProperty(
                                organisations,
                                orgaMembersDetails,
                                user.sub
                              );

                              this.isLoadingOrganisations$.next(false);
                              // convert the dictionary into an array
                              this.organisations$.next(
                                Object.values(organisations)
                              );

                              this.markDefaultOrganisation(organisations);
                            }
                          },
                          error: error =>
                            this.store.dispatch(
                              organisationMembersNotLoaded({
                                organisationError: error.payload
                              })
                            )
                        })
                      );
                    }
                  })
                )
                .subscribe()
            );
          })
        )
        .subscribe()
    );
  }

  markDefaultOrganisation(organisationsDict: UserOrganisations): void {
    this.subscriptions.add(
      this.store
        .select(selectDefaultOrganisation)
        .pipe(filter(result => !!result))
        .subscribe((orgaId: any) => {
          if (organisationsDict[orgaId]) {
            organisationsDict[orgaId]['isDefault'] = true;
          }

          // convert the dictionary into an array
          const organisationsArray = Object.values(organisationsDict);
          const targetIndex = organisationsArray.findIndex(
            element => element.isDefault
          );
          const currentDefaultOrganisation = organisationsArray.splice(
            targetIndex,
            1
          )[0];

          organisationsArray.splice(0, 0, currentDefaultOrganisation);

          this.organisations$.next(organisationsArray);
        })
    );
  }

  getOrganisationsMembers$(filteredOrgs: any): Observable<any> {
    const orgaMembersSubs: any = {};

    for (const orgId in filteredOrgs) {
      orgaMembersSubs[orgId] = this.authService
        .getTokenSilently$()
        .pipe(
          switchMap(token =>
            this.apiService.getOrganisationMembers(orgId, token)
          )
        );
    }

    return forkJoin(orgaMembersSubs);
  }

  addAdminNameProperty(organisations: any, orgaMembersDetails: any) {
    const output: any = {};
    let adminName;

    for (const organisationId in organisations) {
      output[organisationId] = organisations[organisationId];

      const adminMember = orgaMembersDetails[organisationId].content.find(
        (member: any) => member.role === 'ADMIN'
      );

      if (adminMember) {
        adminName = adminMember
          ? adminMember?.givenName + ' ' + adminMember?.familyName
          : 'organisation.unknown';
      }

      output[organisationId] = {
        ...output[organisationId],
        adminName
      };
    }

    return output;
  }

  addMyRoleProperty(
    organisations: any,
    orgaMembersDetails: any,
    myIdpID: string
  ): any {
    const output: any = {};
    let myRole;

    for (const organisationId in organisations) {
      output[organisationId] = organisations[organisationId];

      const member = orgaMembersDetails[organisationId].content.find(
        (member: any) => member.idpUser === myIdpID
      );

      if (member) {
        myRole =
          member.role === 'ADMIN'
            ? 'organisation.role.admin'
            : 'organisation.role.user';
      } else {
        myRole = 'organisation.role.undefined';
      }

      output[organisationId] = {
        ...output[organisationId],
        myRole
      };
    }

    return output;
  }

  openDetailsPage(orgaDetails: any): void {
    this.router.navigate(['/organisation-data'], {
      queryParams: { orgId: orgaDetails.auth0Id }
    });
  }

  setOrganisationRegistrationDialogInput(): IOrganisationRegistrationDialogInput {
    const input = new OrganisationRegistrationDialogInputModel();

    if (this.currentUser) {
      input.user = this.currentUser;
    }

    input.UC_UI = window.UC_UI;
    input.bearerToken = this.accessToken;
    input.apiBaseUrl = environment.base_urls.cc_3_api_base_url;
    input.dialogSteps = this.registrationSteps ? this.registrationSteps : [];
    input.clientId = `${environment.auth.azure_maps_client_id}`;
    input.disableAutostart = true;
    input.registrationDataPending$ = this.store.select(
      selectRegistrationDataPending
    );

    input.searchNearestDealerPending$ = this.store.select(
      selectNearestDealerSearchDataPending
    );

    input.registrationDealerData$ = this.store.select(
      selectRegistrationDealerData
    );
    input.currentOrganisation$ = this.store.select(selectCurrentOrganisation);
    input.searchNearestDealers$ = this.store.select(
      selectNearestDealerSearchData
    );

    input.dealerFilters$ = this.store.select(selectDealerFiltersData);
    input.addressAutocompleteSuggestions$ = this.store.select(
      selectAddressAutocompleteSuggestionsData
    );
    input.addressDetails$ = this.store.select(selectAddressDetails);
    input.searchDealersByName$ = this.store.select(
      selectDealerByNameSearchData
    );
    input.registrationPersonalData$ = this.store.select(
      selectRegistrationPersonalData
    );
    input.registrationError$ = this.store.select(selectRegistrationErrorData);
    input.organisationsError$ = this.store.select(selectOrganisationError);
    input.getRegisteredOrganisation$ = this.store.select(
      selectRegisteredOrganisation
    );
    input.registrationDefaultOrganisation$ = this.store.select(
      selectRegistrationDefaultOrganisation
    );
    input.registrationPersonalDataPending$ = this.store.select(
      selectRegistrationPersonalDataPending
    );

    input.registrationShopData$ = this.store.select(selectRegistrationShopData);
    input.currentDefaultOrganisation$ = this.store.select(
      selectRegistrationDefaultOrganisation
    );

    input.userMarketingPermissions$ = input.user?.uuid
      ? this.apiService
          .getMarketingPermissionsForRegistrationDialog(input.user?.uuid)
          .pipe(take(1))
      : EMPTY;

    return input;
  }

  openRegistrationDialog(): void {
    const data: IOrganisationRegistrationDialogInput =
      this.setOrganisationRegistrationDialogInput();

    const dialogRef = this.dialog.open(
      OrganisationRegistrationDialogComponent,
      {
        data: data,
        disableClose: true
      }
    );

    this.processWithDialogRef(dialogRef);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((response: any) => {
        if (response && response !== '') {
          this.isLoadingOrganisations$.next(true);

          this.store
            .select(selectRegisteredOrganisation)
            .pipe(filter((result: any) => !!result))
            .subscribe(result => this.getOrganisations());
        }
      });
  }

  openDialog(context: string): void {
    const data: passedDataToDialog = {
      context,
      buttonCancel: 'Cancel',
      buttonSave: 'Save',
      orgUpdateRequest: this.organisationUpdateRequest
    };

    const dialogRef = this.dialog.open(DialogWindowComponent, {
      maxWidth: this.isHandset ? '100vw' : '80vw',
      data
    });

    this.subscriptions.add(
      dialogRef
        .afterClosed()
        .pipe(
          tap((answer: ResponseDataFromDialog) => {
            this.getOrganisations();
          })
        )
        .subscribe()
    );
  }

  processWithDialogRef(dialogRef: MatDialogRef<any>): void {
    this.subscriptions.add(
      dialogRef.componentInstance.getDealerFilter.subscribe(() =>
        this.store.dispatch(loadDealerFiltersData())
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getNearestDealers.subscribe(
        (request: any) => {
          this.store.dispatch(loadNearestDealersSearchData(request));
        }
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getAddressAutocomplete.subscribe(
        (request: IAddressAutocompleteRequest) =>
          this.store.dispatch(
            loadAddressAutocompleteSuggestionsData({
              addressDetailsRequest: request
            })
          )
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getDealersByName.subscribe((request: any) => {
        this.store.dispatch(loadDealerByNameSearchData(request));
      })
    );

    this.subscriptions.add(
      dialogRef.componentInstance.clearRegistrationData.subscribe(() =>
        this.store.dispatch(clearRegistrationData())
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getRegistrationDefaultOrganisation.subscribe(
        (country: string) =>
          this.store.dispatch(loadRegistrationDefaultOrganisation({ country }))
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getRegistrationShopData.subscribe(
        (request: any) =>
          this.store.dispatch(loadRegistrationShopData({ country: request }))
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getRegistrationPersonalData.subscribe(
        (request: any) => {
          this.store.dispatch(
            loadRegistrationPersonalData({
              country: request.country,
              language: request.language
            })
          );
        }
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getRegistrationDealerData.subscribe(
        (request: any) => {
          this.store.dispatch(
            loadRegistrationDealerData({
              language: request.language
            })
          );
        }
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.maintainPersonalData.subscribe(
        (request: any) =>
          this.store.dispatch(
            updateRegistrationPersonalData({
              request: request.request,
              language: request.language
            })
          )
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.registerOrganisationWithDealerAndShops.subscribe(
        (request: any) => this.store.dispatch(registerOrganisation(request))
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.stepperError.subscribe((message: any) => {
        console.log(message);
      })
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getAddressDetails
        .pipe(take(1))
        .subscribe((request: IAddressDetailsRequest) =>
          this.store.dispatch(
            loadAddressDetails({ addressDetailsRequest: request })
          )
        )
    );

    // Marketing permissions
    this.subscriptions.add(
      dialogRef.componentInstance.persistClaasMarketingPermission.subscribe(
        (request: any) =>
          this.marketingPermissionService.persistClaasMarketingPermission(
            request
          )
      )
    );
    this.subscriptions.add(
      dialogRef.componentInstance.persistDealerMarketingPermissions.subscribe(
        (request: any) =>
          this.marketingPermissionService.persistDealerMarketingPermissions(
            request
          )
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
