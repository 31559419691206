import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ElementTypeOptions,
  ResponseDataFromDialog,
  TargetOptions,
  passedDataToDialog,
  DialogWindowComponent
} from '../dialog-window/dialog-window.component';
import { ButtonClass, ButtonSize } from '@claas/claas-form-components';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  finalize,
  forkJoin,
  of,
  switchMap,
  take,
  tap
} from 'rxjs';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormControl,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { UserAddress } from '../../models/user-address.model';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../services/api.service';
import { UserAddressOption } from '../../models/user-address-option.model';
import { UserAddressField } from '../../models/user-address-field.model';
import { filter, map } from 'rxjs/operators';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { TranslateService } from '@ngx-translate/core';
import { OrgAddress } from '../../models/org-address.model';
import { OrgAddressPatch } from '../../models/org-address-patch.model';
import { MatDialog } from '@angular/material/dialog';
import { AddressService } from '../../services/address.service';
import { AuthService } from '../../services/auth.service';

export interface Option {
  key: string;
  text: string;
}

export interface OptionObs {
  [key: string]: Observable<string>;
}

export interface CountryDetails {
  id: string;
  label: string;
  img: string;
  code: string;
  iso: string;
}

export interface StateDetailsCollection {
  [key: string]: StateDetails[];
}

export interface TerritoryDetails {
  id: string;
  label: string;
}

export interface TerritoryDetailsCollection {
  [key: string]: TerritoryDetails[];
}

export interface StateDetails {
  id: string;
  label: string;
}

export interface FieldDetails {
  [key: string]: UserAddressField;
}

export interface errorStatusObject {
  [key: string]: undefined | BehaviorSubject<any>;
}

@Component({
  selector: 'app-address-view',
  templateUrl: './address-view.component.html',
  styleUrls: ['./address-view.component.scss']
})
export class AddressViewComponent implements OnDestroy, OnInit {
  @Input() data: passedDataToDialog | undefined;
  @Input() errorButtonClass?: ButtonClass;
  @Input() primaryTextButtonClass?: ButtonClass;
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() displayPopup = new EventEmitter();
  @Output() closeDialogWindow = new EventEmitter();

  countryDetails: CountryDetails[] = environment.general.countriesDetails;
  // refers to the list of countries, we can select for the address
  countryOptions: Option[] = [];
  statesDetails: StateDetailsCollection = environment.general.statesDetails;
  // refers to the list of states, we can select for the address
  territoryDetails: TerritoryDetailsCollection =
    environment.general.territoriesDetails;
  // refers to the list of territories, we can select for the address
  stateOptions: Option[] = [];
  territoryOptions: Option[] = [];
  myForm: FormGroup | undefined;
  currentAddressFormatObject: FieldDetails = {};
  subscriptions = new Subscription();
  mySearchFieldControl: UntypedFormControl;
  existingAddressesFormatsObject: any = {};
  defaultAddressCountry = 'de';
  postalCodeValid: boolean = true;
  postalCodesRegex: any = {};
  countryObj: any = {};
  houseNumberInStreetCountries = ['us', 'ca', 'ie', 'gb'];

  largeSize = CircularProgressDiameter.LARGE;
  mediumButtonSize = ButtonSize.DEFAULT_MEDIUM;
  secondaryColor = ProgressColor.SECONDARY;
  isHandset = false;

  errorsStatusObject$: errorStatusObject = {};
  myForm$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  getAutoCompleteOptions$ = new BehaviorSubject<UserAddressOption[]>([]);
  displayAddressFields$ = new BehaviorSubject(false);
  invalidSaveButtonState$ = new BehaviorSubject(true);
  savingInProgress$ = new BehaviorSubject<boolean>(false);
  confirmationDialogIsActive$ = new BehaviorSubject(false);

  invoice = ElementTypeOptions.INVOICE;

  size = CircularProgressDiameter.SMALL;
  color = ProgressColor.PRIMARY;
  isSaving = false;

  constructor(
    private apiService: ApiService,
    private auth: AuthService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private addressService: AddressService
  ) {
    this.countryOptions = this.setCountryOptions();
    this.getOptionsTranslation(this.countryOptions);
    this.mySearchFieldControl = this.setSearchField();
  }

  ngOnInit() {
    this.trackSearchFieldChanges(
      this.data?.userLanguage ? this.data.userLanguage : 'de',
      this.data?.userCountry ? this.data.userCountry : 'de'
    );

    this.subscriptions.add(this.getPostalCodeRegex().subscribe());

    this.initialFormFilling().subscribe(() => {
      this.myForm
        ?.get(
          this.data?.target === 'organisation' ? 'postalCode' : 'postal_code'
        )
        ?.valueChanges.subscribe(value => {
          this.validatePostalCode(value);
        });

      this.stateOptions = this.setStateOption(true);
      this.getOptionsTranslation(this.stateOptions);

      this.territoryOptions = this.setTerritoryOption(true);
      this.getOptionsTranslation(this.territoryOptions);
    });
  }

  getOptionsTranslation(options: Option[]): void {
    const obsArray: OptionObs = {};

    options.forEach(
      (option: Option) =>
        (obsArray[option.key] = this.translateService.get(option.text))
    );

    this.subscriptions.add(
      forkJoin(obsArray).subscribe(translation => {
        for (const key in translation) {
          const target: Option | undefined = options.find(
            (element: Option) => element.key === key
          );

          if (target) {
            target.text = translation[key];
          }
        }
        options.sort((a, b) =>
          a.text ? a.text.localeCompare(b.text ?? '') : 1
        );
      })
    );
  }

  initialFormFilling(): Observable<void> {
    let addressDetails: UserAddress | OrgAddress;

    if (this.data?.target === 'organisation') {
      // IN CASE WE ARE HANDLING AN ORGANISATION ADDRESS
      addressDetails = new OrgAddress();

      if (this.data.organisationData.addresses) {
        const targetAddress = this.data.organisationData.addresses
          .filter((add: OrgAddress) => add.type === this.data?.elementType)
          ?.shift();

        addressDetails = targetAddress ? targetAddress : addressDetails;
        this.defaultAddressCountry = addressDetails.country;
      }
    } else {
      // IN CASE WE ARE HANDLING A USER ADDRESS
      addressDetails = new UserAddress();

      // only if Edit usecase
      if (
        this.data?.addressDetails &&
        this.data.addressDetails.addresses &&
        (this.data.addressDetails.currentIndex ||
          this.data.addressDetails.currentIndex === 0)
      ) {
        const currentAddress =
          this.data.addressDetails.addresses[
            this.data.addressDetails.currentIndex
          ];

        this.defaultAddressCountry = currentAddress.country;

        addressDetails.copyAddress(
          currentAddress,
          this.defaultAddressCountry !== 'de'
        );

        this.defaultAddressCountry = addressDetails.country;
      } else {
        // only if New usecase (no addresses): set defaultAddressCountry to userCountry
        if (this.data?.userCountry) {
          this.defaultAddressCountry = this.data?.userCountry;
        }
      }
    }

    return this.getAddressesFormats().pipe(
      tap((result: any) => {
        this.setCurrentAddressFormatObject(this.defaultAddressCountry);

        // Generate the FormGroup
        this.setForm(addressDetails, this.currentAddressFormatObject);

        this.trackFormChanges();
        this.myForm$.next(this.myForm);

        if (this.data?.subContext === 'edit') {
          this.displayAddressFields$.next(true);
        }
      }),
      map(() => void 0)
    );
  }

  setCurrentAddressFormatObject(currentCountry: string): void {
    const currentAddressFormatArray = this.existingAddressesFormatsObject[
      currentCountry
    ].flatMap((subArray: any) => [...subArray]);

    this.currentAddressFormatObject = {};

    currentAddressFormatArray.map(
      (details: any) => (this.currentAddressFormatObject[details.id] = details)
    );
  }

  searchAutoFormFilling(details: any): void {
    const countriesWithException = ['us', 'ca', 'ie', 'gb'];
    const chosenAddress = new UserAddress();

    if ('houseNumber' in details) {
      chosenAddress.house_number = details['houseNumber'];
    }

    if ('street' in details) {
      chosenAddress.street = details['street'];
    }

    if ('city' in details) {
      chosenAddress.city = details['city'];
    }

    if ('country' in details) {
      chosenAddress.country = details['country']?.toLowerCase();
    }

    if ('territory' in details) {
      chosenAddress.territory = details['territory']?.toLowerCase();
    }

    if ('state' in details) {
      chosenAddress.state = details['state']?.toLowerCase();
    }

    if ('postalCode' in details) {
      chosenAddress.postal_code = details['postalCode'];
    }

    if ('suburb' in details) {
      chosenAddress.suburb = details['suburb'];
    }

    this.defaultAddressCountry = chosenAddress.country;

    if (
      countriesWithException.includes(chosenAddress.country) &&
      chosenAddress.house_number
    ) {
      chosenAddress.street =
        chosenAddress.house_number + ' ' + chosenAddress.street;
      chosenAddress.house_number = '';
    }

    this.subscriptions.add(
      // Get the existing Addresses Format + add the current address Format if it is missing
      this.getAddressesFormats().subscribe((result: any) => {
        // Get the Address Format based on the country
        this.setCurrentAddressFormatObject(this.defaultAddressCountry);

        this.stateOptions = this.setStateOption();
        this.getOptionsTranslation(this.stateOptions);

        this.territoryOptions = this.setTerritoryOption();
        this.getOptionsTranslation(this.territoryOptions);

        this.myForm$.next(undefined);

        // Generate the FormGroup
        this.setForm(chosenAddress, this.currentAddressFormatObject, true);
      })
    );
  }

  onCountryChange(country: any): void {
    // another country has been chosen for the address
    this.defaultAddressCountry = country;

    this.stateOptions = this.setStateOption();
    this.getOptionsTranslation(this.stateOptions);

    this.territoryOptions = this.setTerritoryOption();
    this.getOptionsTranslation(this.territoryOptions);
    this.minAutoFormFilling();
  }

  minAutoFormFilling(): void {
    const newAddress = new UserAddress();

    newAddress.country = this.defaultAddressCountry;

    this.subscriptions.add(
      // Get the existing Addresses Format + add the current address Format if it is missing
      this.getAddressesFormats().subscribe((result: any) => {
        // Get the Address Format based on the country
        this.setCurrentAddressFormatObject(this.defaultAddressCountry);

        this.myForm$.next(undefined);

        // Generate the FormGroup
        this.setForm(newAddress, this.currentAddressFormatObject);
      })
    );
  }

  getAddressesFormats(): Observable<any> {
    return forkJoin([
      this.getExistingAddressesFormats$(),
      this.getSelectedAddressFormat$(this.defaultAddressCountry)
    ]).pipe(
      map(([multipleFormats, singleFormat]) => {
        const multipleFormFields: any = multipleFormats ? multipleFormats : {};

        for (const key in multipleFormFields) {
          multipleFormFields[key] = multipleFormFields[
            key
          ].allAddressDataRows.filter((row: any[]) => row.length);
        }

        multipleFormFields[this.defaultAddressCountry] =
          singleFormat.allAddressDataRows.filter((row: any[]) => row.length);

        this.existingAddressesFormatsObject = multipleFormFields;
      })
    );
  }

  setForm(
    addressDetails: UserAddress | OrgAddress,
    addressFormatObject: FieldDetails,
    isFromSearch?: boolean
  ): void {
    if (this.data?.target === TargetOptions.ORGANISATION) {
      const organisationAddress = new OrgAddress(addressDetails);

      this.setFormForOrganisation(
        organisationAddress,
        addressFormatObject,
        isFromSearch
      );
    } else {
      const profileAddress = new UserAddress(addressDetails);

      if (this.data?.userCountry) {
        profileAddress.country = this.data.userCountry;
      }

      this.setFormForProfile(profileAddress, addressFormatObject, isFromSearch);
    }
  }

  setFormForOrganisation(
    addressDetails: OrgAddress,
    addressFormatObject: FieldDetails,
    isFromSearch?: boolean
  ): void {
    const country = this.countryOptions.find(
      el => el.key === addressDetails.country
    );

    // create a basic formGroup
    const formGroup: FormGroup = new FormGroup({
      legalName: new FormControl(
        addressDetails.legalName ? addressDetails.legalName : '-'
      ),
      country: new FormControl({
        value:
          this.data?.subContext !== 'edit'
            ? addressDetails.country
            : country?.text,
        disabled: this.data?.subContext === 'edit'
      })
    });

    // add the extra form Controls based on the current address Format
    if (addressFormatObject['street']) {
      formGroup.addControl(
        'street',
        new FormControl(
          addressDetails.street,
          addressFormatObject['street'].mandatory ? [Validators.required] : []
        )
      );
    }

    if (addressFormatObject['houseNumber']) {
      formGroup?.addControl(
        'houseNumber',
        new FormControl(
          addressDetails.houseNumber,
          addressFormatObject['houseNumber'].mandatory
            ? [Validators.required]
            : []
        )
      );
    }

    if (addressFormatObject['postalCode']) {
      formGroup?.addControl(
        'postalCode',
        new FormControl(addressDetails.postalCode, {
          validators: addressFormatObject['postalCode'].mandatory
            ? [Validators.required]
            : []
        })
      );
    }

    if (addressFormatObject['city']) {
      formGroup?.addControl(
        'city',
        new FormControl(
          addressDetails.city,
          addressFormatObject['city'].mandatory ? [Validators.required] : []
        )
      );
    }

    if (addressFormatObject['addressAddition']) {
      formGroup?.addControl(
        'addressAddition',
        new FormControl(
          addressDetails.addressAddition,
          addressFormatObject['addressAddition'].mandatory
            ? [Validators.required]
            : []
        )
      );
    }

    if (addressFormatObject['suburb']) {
      formGroup?.addControl(
        'suburb',
        new FormControl(
          addressDetails.suburb,
          addressFormatObject['suburb'].mandatory ? [Validators.required] : []
        )
      );
    }

    if (addressFormatObject['territory']) {
      formGroup?.addControl(
        'territory',
        new FormControl(
          addressDetails.territory,
          addressFormatObject['territory'].mandatory
            ? [Validators.required]
            : []
        )
      );
    }

    if (addressFormatObject['state']) {
      formGroup?.addControl(
        'state',
        new FormControl(
          addressDetails.state,
          addressFormatObject['state'].mandatory ? [Validators.required] : []
        )
      );
    }

    this.myForm = formGroup;
    this.preTrackFormChanges(this.myForm, isFromSearch);
    this.displayAddressFields$.next(true);
    this.myForm$.next(this.myForm);
  }

  setFormForProfile(
    addressDetails: UserAddress,
    addressFormatObject: FieldDetails,
    isFromSearch?: boolean
  ): any {
    const country = this.countryOptions.find(
      el => el.key === addressDetails.country
    );

    // create a basic formGroup
    const formGroup: FormGroup = new FormGroup({
      name: new FormControl(addressDetails.name ? addressDetails.name : '-'),
      country: new FormControl(
        {
          value:
            this.data?.subContext !== 'edit'
              ? addressDetails.country
              : country?.text,
          disabled: this.data?.subContext === 'edit'
        },
        [Validators.required]
      )
    });

    // add the extra form Controls based on the current address Format
    if (addressFormatObject['street']) {
      formGroup.addControl(
        'street',
        new FormControl(
          addressDetails.street,
          addressFormatObject['street'].mandatory ? [Validators.required] : []
        )
      );
    }

    if (addressFormatObject['houseNumber']) {
      formGroup?.addControl(
        'house_number',
        new FormControl(
          addressDetails.house_number,
          addressFormatObject['houseNumber'].mandatory
            ? [Validators.required]
            : []
        )
      );
    }

    if (addressFormatObject['postalCode']) {
      formGroup?.addControl(
        'postal_code',
        new FormControl(
          addressDetails.postal_code,
          addressFormatObject['postalCode'].mandatory
            ? [Validators.required]
            : []
        )
      );
    }

    if (addressFormatObject['city']) {
      formGroup?.addControl(
        'city',
        new FormControl(
          addressDetails.city,
          addressFormatObject['city'].mandatory ? [Validators.required] : []
        )
      );
    }

    if (addressFormatObject['addressAddition']) {
      formGroup?.addControl(
        'addressAddition',
        new FormControl(
          addressDetails.addressAddition,
          addressFormatObject['addressAddition'].mandatory
            ? [Validators.required]
            : []
        )
      );
    }

    if (addressFormatObject['suburb']) {
      formGroup?.addControl(
        'suburb',
        new FormControl(
          addressDetails.suburb,
          addressFormatObject['suburb'].mandatory ? [Validators.required] : []
        )
      );
    }

    if (addressFormatObject['territory']) {
      formGroup?.addControl(
        'territory',
        new FormControl(
          addressDetails.territory,
          addressFormatObject['territory'].mandatory
            ? [Validators.required]
            : []
        )
      );
    }

    if (addressFormatObject['state']) {
      formGroup?.addControl(
        'state',
        new FormControl(
          addressDetails.state,
          addressFormatObject['state'].mandatory ? [Validators.required] : []
        )
      );
    }

    this.myForm = formGroup;
    this.myForm.setValidators(
      Validators.compose([this.myForm.validator, this.customFormValidator()])
    );
    this.preTrackFormChanges(this.myForm, isFromSearch);
    this.displayAddressFields$.next(true);
    this.myForm$.next(this.myForm);
  }

  customFormValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const formGroup = control as FormGroup;
      const postalCodeControl = formGroup.get(
        this.data?.target === 'organisation' ? 'postalCode' : 'postal_code'
      );

      if (postalCodeControl && !this.validatePostalCode(postalCodeControl)) {
        return { postalCodeInvalid: true };
      } else {
        return null;
      }
    };
  }

  preTrackFormChanges(myForm: FormGroup, isFromSearch?: boolean): void {
    const valid = isFromSearch ? !this.myForm?.invalid : false;

    this.invalidSaveButtonState$.next(!valid);
    this.trackFormChanges();
  }

  trackFormChanges(): void {
    for (const formControlKey in this.myForm?.controls) {
      if (this.myForm?.controls.hasOwnProperty(formControlKey)) {
        this.errorsStatusObject$[formControlKey] = new BehaviorSubject<
          undefined | string
        >(undefined);
      }
    }
  }

  updateValidity(input: any, fieldKey: string) {
    const errorMessage =
      !input || input === ''
        ? 'registration.validationMsgs.required'
        : undefined;

    this.errorsStatusObject$[fieldKey]?.next(errorMessage);

    if (!!errorMessage) {
      this.invalidSaveButtonState$.next(true);
    } else {
      this.invalidSaveButtonState$.next(false);
      // check if there are other invalid required fields
      this.checkAllFields();
    }
  }

  checkAllFields(): void {
    for (const requiredFieldsKey in this.errorsStatusObject$) {
      if (
        requiredFieldsKey !== 'legalName' &&
        requiredFieldsKey !== 'country' &&
        this.currentAddressFormatObject[requiredFieldsKey].mandatory &&
        this.errorsStatusObject$.hasOwnProperty(requiredFieldsKey)
      ) {
        this.subscriptions.add(
          this.errorsStatusObject$[requiredFieldsKey]
            ?.pipe(
              filter(invalid => !!invalid),
              take(1)
            )
            .subscribe(() => this.invalidSaveButtonState$.next(true))
        );
      }
    }
  }

  getExistingAddressesFormats$(): Observable<any> {
    if (this.data?.addressDetails?.addresses?.length) {
      const obsElementsObject: any = {};

      this.data.addressDetails.addresses.forEach((address: UserAddress) => {
        // create a dictionary of observables
        obsElementsObject[address.country] =
          this.apiService.getFormFieldsForCountry(address.country);
      });

      // we assemble the service calls to fetch Form fields
      return forkJoin(obsElementsObject);
    }

    return of(undefined);
  }

  getSelectedAddressFormat$(country: string): Observable<any> {
    return this.apiService.getFormFieldsForCountry(country);
  }

  getSelectedAddressDetails(userAddressOption: UserAddressOption): void {
    if (userAddressOption?.id) {
      this.subscriptions.add(
        this.apiService
          .getAddressDetails(
            userAddressOption.id,
            this.data?.userLanguage ? this.data.userLanguage : 'de',
            userAddressOption?.country
              ? userAddressOption.country
              : this.data?.userCountry
                ? this.data.userCountry
                : 'de'
          )
          .subscribe({
            next: details => this.searchAutoFormFilling(details),
            error: err => {
              const text = JSON.stringify(err);
              this.displayPopup.emit({ text, className: 'error' });
            }
          })
      );
    }
  }

  setCountryOptions(): Option[] {
    return this.countryDetails.map(detail => {
      return { key: detail.id, text: detail.label };
    });
  }

  setSearchField(): FormControl {
    return new UntypedFormControl('');
  }

  trackSearchFieldChanges(userLanguage: string, userCountry: string): void {
    this.subscriptions.add(
      this.mySearchFieldControl.valueChanges
        .pipe(
          switchMap((value: string) => {
            if (value !== '') {
              return this.apiService.getAddressAutoComplete(
                value,
                userLanguage,
                this.defaultAddressCountry
                  ? this.defaultAddressCountry
                  : userCountry
              );
            } else return of([]);
          })
        )
        .subscribe((values: any) => {
          const minimalVersionOfValue: UserAddressOption[] =
            values.content?.map(
              ({ id, description, category, country }: UserAddressOption) => ({
                id,
                description,
                category,
                country
              })
            );

          this.getAutoCompleteOptions$.next(minimalVersionOfValue);
        })
    );
  }

  setStateOption(isInitialFormFill?: boolean): Option[] {
    let currentIndex = 0;
    let currentCountry = this.defaultAddressCountry;

    if (isInitialFormFill) {
      if (
        this.data?.addressDetails &&
        (this.data?.addressDetails?.currentIndex ||
          this.data?.addressDetails?.currentIndex === 0)
      ) {
        currentIndex = this.data.addressDetails.currentIndex;
        currentCountry =
          this.data.addressDetails.addresses[currentIndex].country;
      }
    }

    return this.statesDetails[currentCountry]
      ? this.statesDetails[currentCountry].map(details => {
          return { key: details.id, text: details.label };
        })
      : [];
  }

  setTerritoryOption(isInitialFormFill?: boolean): Option[] {
    let currentIndex = 0;
    let currentCountry = this.defaultAddressCountry;

    if (isInitialFormFill) {
      if (
        this.data?.addressDetails &&
        (this.data?.addressDetails?.currentIndex ||
          this.data?.addressDetails?.currentIndex === 0)
      ) {
        currentIndex = this.data.addressDetails.currentIndex;
        currentCountry =
          this.data.addressDetails.addresses[currentIndex].country;
      }
    }

    return this.territoryDetails[currentCountry]
      ? this.territoryDetails[currentCountry].map(details => {
          return { key: details.id, text: details.label };
        })
      : [];
  }

  onCancelClicked(): void {
    const passedObject = {
      action: 'cancel'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  preUpdateOrganisationAddress(): OrgAddressPatch | undefined {
    if (this.data?.organisationData && this.data.target) {
      const updateValue: OrgAddressPatch = new OrgAddressPatch(
        this.myForm?.value
      );

      updateValue.type = this.data?.elementType;
      updateValue.country = this.data?.organisationData?.country;

      return updateValue;
    }

    return undefined;
  }

  preUpdateUserAddress(): UserAddress | undefined {
    if (
      this.data?.addressDetails?.currentIndex ||
      this.data?.addressDetails?.currentIndex === 0
    ) {
      const editedAddress = new UserAddress(this.myForm?.value);
      const index = this.data.addressDetails.currentIndex;
      const targetAddress = new UserAddress(
        this.data.addressDetails.addresses[index]
      );
      targetAddress.copyAddress(editedAddress);

      return targetAddress;
    }

    // this part is actually never reached, just to avoid tslint error
    return;
  }

  preAdditionUserAddress(): UserAddress {
    const newAddress = new UserAddress();
    const myForm = this.myForm?.value;

    newAddress.name = myForm.name;
    newAddress.street = myForm.street;
    newAddress.house_number = myForm.house_number;
    newAddress.postal_code = myForm.postal_code;
    newAddress.city = myForm.city;

    if (myForm.state) {
      newAddress.state = myForm.state;
    }

    if (myForm.suburb) {
      newAddress.suburb = myForm.suburb;
    }

    if (myForm.addressAddition) {
      newAddress.addressAddition = myForm.addressAddition;
    }

    if (myForm.territory) {
      newAddress.territory = myForm.territory;
    }

    newAddress.country = this.defaultAddressCountry;

    return newAddress;
  }

  updateOrganisationAddress(
    orgaId: string,
    addressId: string,
    updatedAddress: OrgAddressPatch,
    passedObject: ResponseDataFromDialog
  ): void {
    this.subscriptions.add(
      this.auth
        .getTokenSilently$()
        .pipe(
          switchMap(token =>
            this.apiService.updateOrganisationAddress(
              orgaId,
              addressId,
              updatedAddress,
              token
            )
          ),
          finalize(() => this.closeDialogWindow.emit(passedObject))
        )
        .subscribe({
          next: value => {
            // Update Organisation address
            this.translateService
              .get('personal_data.address.update.success')
              .subscribe(text => {
                passedObject.refreshIsRequired = true;
                this.displayPopup.emit({ text, className: '' });
              });
          },
          error: err => {
            this.translateService
              .get('personal_data.address.update.fail')
              .subscribe(text => {
                passedObject.refreshIsRequired = true;
                this.displayPopup.emit({ text, className: 'error' });
              });
          }
        })
    );
  }

  updateUserAddress(
    updatedAddress: UserAddress,
    passedObject: ResponseDataFromDialog
  ): void {
    this.subscriptions.add(
      this.apiService
        .updateAddress(updatedAddress)
        .pipe(finalize(() => this.closeDialogWindow.emit(passedObject)))
        .subscribe({
          next: value => {
            // Update User address
            this.translateService
              .get('personal_data.address.update.success')
              .subscribe(text => {
                passedObject.refreshIsRequired = true;
                this.displayPopup.emit({ text, className: '' });
              });
          },
          error: err => {
            this.translateService
              .get('personal_data.address.update.fail')
              .subscribe(text => {
                passedObject.refreshIsRequired = true;
                this.displayPopup.emit({ text, className: 'error' });
              });
          }
        })
    );
  }

  addUserAddress(
    newAddress: UserAddress,
    passedObject: ResponseDataFromDialog
  ): void {
    this.subscriptions.add(
      this.apiService
        .addAddress(newAddress)
        .pipe(finalize(() => this.closeDialogWindow.emit(passedObject)))
        .subscribe({
          next: value => {
            // Add address
            this.translateService
              .get('personal_data.address.add.success')
              .subscribe(text => {
                passedObject.refreshIsRequired = true;
                this.displayPopup.emit({ text, className: '' });
              });
          },
          error: err => {
            this.translateService
              .get('personal_data.address.add.fail')
              .subscribe(text => {
                passedObject.refreshIsRequired = true;
                this.displayPopup.emit({ text, className: 'error' });
              });
          }
        })
    );
  }

  onSaveClicked(): void {
    this.isSaving = true;
    if (!this.myForm?.invalid) {
      const passedObject: ResponseDataFromDialog = {
        action: 'save'
      };

      this.invalidSaveButtonState$.next(true);
      this.savingInProgress$.next(true);

      if (this.data?.subContext === 'edit') {
        let updatedAddress: UserAddress | OrgAddressPatch | undefined;

        if (this.data.target === 'organisation') {
          // organisation use case
          updatedAddress = this.preUpdateOrganisationAddress();

          const targetAddress = this.data.organisationData.addresses
            .filter((add: OrgAddress) => add.type === this.data?.elementType)
            ?.shift();

          if (updatedAddress) {
            this.addressService
              .validateOrgAddress(updatedAddress)
              .subscribe((proposedAddresses: OrgAddressPatch[]) => {
                if (proposedAddresses.length > 0 && updatedAddress) {
                  // Show validation dialog when proposed address is different than user address
                  this.openDialog(
                    'address-validation',
                    updatedAddress,
                    proposedAddresses
                    // @ts-ignore
                  ).subscribe((address: OrgAddressPatch) => {
                    if (address && updatedAddress instanceof OrgAddressPatch) {
                      address.type = updatedAddress.type;

                      // Save street and number in same field for some countries
                      if (
                        this.houseNumberInStreetCountries.includes(
                          address.country.toLowerCase()
                        )
                      ) {
                        var street = '';
                        if (address.street) {
                          street = address.street;
                        }
                        if (address.houseNumber) {
                          street = street + ' ' + address.houseNumber;
                        }
                        address.street = street;
                      }

                      this.updateOrganisationAddress(
                        this.data?.organisationData.auth0Id,
                        targetAddress.id,
                        address,
                        passedObject
                      );
                    }
                  });
                } else {
                  if (updatedAddress instanceof OrgAddressPatch) {
                    this.updateOrganisationAddress(
                      this.data?.organisationData.auth0Id,
                      targetAddress.id,
                      updatedAddress,
                      passedObject
                    );
                  }
                }
              });
          }
        } else {
          // user profile use case
          updatedAddress = this.preUpdateUserAddress();

          // validate address here before saving
          if (updatedAddress) {
            this.addressService
              .validateUserAddress(updatedAddress)
              .subscribe((proposedAddresses: UserAddress[]) => {
                if (proposedAddresses.length > 0 && updatedAddress) {
                  // Show validation dialog when proposed address is different than user address
                  this.openDialog(
                    'address-validation',
                    updatedAddress,
                    proposedAddresses
                    // @ts-ignore
                  ).subscribe((address: UserAddress) => {
                    if (address && updatedAddress instanceof UserAddress) {
                      address.name = updatedAddress.name;
                      address.uuid = updatedAddress.uuid;
                      // Save street and number in same field for some countries
                      if (
                        this.houseNumberInStreetCountries.includes(
                          address.country.toLowerCase()
                        )
                      ) {
                        var street = '';
                        if (address.street) {
                          street = address.street;
                        }
                        if (address.house_number) {
                          street = street + ' ' + address.house_number;
                        }
                        address.street = street;
                      }
                      this.updateUserAddress(address, passedObject);
                    }
                  });
                } else {
                  if (updatedAddress instanceof UserAddress) {
                    this.updateUserAddress(updatedAddress, passedObject);
                  }
                }
              });
          }
        }
      } else {
        const newAddress = this.preAdditionUserAddress();

        this.addressService
          .validateUserAddress(newAddress)
          .subscribe((proposedAddresses: UserAddress[]) => {
            if (proposedAddresses.length > 0 && newAddress) {
              // Show validation dialog when proposed address is different than user address
              this.openDialog(
                'address-validation',
                newAddress,
                proposedAddresses
                // @ts-ignore
              ).subscribe((address: UserAddress) => {
                if (address && newAddress) {
                  address.name = newAddress.name;
                  // Save street and number in same field for some countries
                  if (
                    this.houseNumberInStreetCountries.includes(
                      address.country.toLowerCase()
                    )
                  ) {
                    var street = '';
                    if (address.street) {
                      street = address.street;
                    }
                    if (address.house_number) {
                      street = street + ' ' + address.house_number;
                    }
                    address.street = street;
                  }
                  this.addUserAddress(address, passedObject);
                }
              });
            } else {
              this.addUserAddress(newAddress, passedObject);
            }
          });
      }
    }
  }

  showConfirmationDialog(): void {
    this.confirmationDialogIsActive$.next(true);
  }

  deletionIsCanceled(): void {
    this.confirmationDialogIsActive$.next(false);
  }

  deletionIsConfirmed(): void {
    this.confirmationDialogIsActive$.next(false);
    this.onDeleteClicked();
  }

  onDeleteClicked(): void {
    this.isSaving = true;
    const passedObject: ResponseDataFromDialog = {
      context: this.data?.context,
      action: 'delete'
    };

    this.deleteAddress(passedObject);
  }

  deleteAddress(passedObject: ResponseDataFromDialog): void {
    if (
      this.data &&
      this.data.addressDetails &&
      (this.data.addressDetails.currentIndex ||
        this.data.addressDetails.currentIndex === 0)
    ) {
      const targetAddressIndex = this.data.addressDetails.currentIndex;
      const targetAddress =
        this.data.addressDetails.addresses[targetAddressIndex];

      if (targetAddress.uuid) {
        this.subscriptions.add(
          this.apiService
            .deleteAddress(targetAddress.uuid)
            .pipe(finalize(() => this.closeDialogWindow.emit(passedObject)))
            .subscribe({
              next: value => {
                // Delete the Address
                this.translateService
                  .get('personal_data.address.delete.success')
                  .subscribe(text => {
                    passedObject.refreshIsRequired = true;
                    this.displayPopup.emit({ text, className: '' });
                  });
              },
              error: err => {
                this.translateService
                  .get('personal_data.address.delete.fail')
                  .subscribe(text => {
                    passedObject.refreshIsRequired = true;
                    this.displayPopup.emit({ text, className: 'error' });
                  });
              }
            })
        );
      }
    }
  }

  getPostalCodeRegex(): Observable<Object> {
    return this.apiService.getPostalCodeRegex().pipe(
      tap((res: any) => {
        this.postalCodesRegex = res;
      })
    );
  }

  validatePostalCode(event: any) {
    const country = this.myForm?.get('country')?.value;
    const postalCodeControl = this.myForm?.get(
      this.data?.target === 'organisation' ? 'postalCode' : 'postal_code'
    );

    if (postalCodeControl) {
      const postalCode = postalCodeControl.value;
      this.postalCodeValid = this.checkPostalCode(
        postalCode,
        this.defaultAddressCountry
      );
    } else {
      this.postalCodeValid = true;
    }

    return this.postalCodeValid;
  }

  checkPostalCode(postalCode: string, country: string) {
    if (this.postalCodesRegex) {
      this.countryObj = this.postalCodesRegex.find((codes: any) => {
        return codes.iso == country.toUpperCase();
      });
    } else {
      return true;
    }
    return (
      this.countryObj == null ||
      postalCode.match(this.countryObj.regex) !== null
    );
  }

  openDialog(
    context: string,
    inputAddress: UserAddress | OrgAddressPatch,
    proposedAddresses: UserAddress[] | OrgAddressPatch[]
  ): Observable<UserAddress | OrgAddressPatch> {
    const data: passedDataToDialog = {
      context,
      inputAddress: inputAddress,
      proposedAddresses: proposedAddresses,
      buttonSave: 'Save'
    };

    const dialogRef = this.dialog.open(DialogWindowComponent, {
      maxWidth: this.isHandset ? '100vw' : '80vw',
      data
    });

    return dialogRef.afterClosed().pipe(
      map((answer: ResponseDataFromDialog) => {
        if (answer && answer.selectedAddress) {
          return answer.selectedAddress;
        }
        return inputAddress;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
