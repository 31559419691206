import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from '../../services/api.service';
import {
  addressDetailsLoaded,
  addressDetailsNotLoaded,
  currentOrganisationLoaded,
  loadAddressDetails,
  loadOrganisationRegistrationSteps,
  loadRegistrationDefaultOrganisation,
  loadUserDefaultOrganisation,
  loadUserOrganisations,
  organisationDataNotLoaded,
  organisationRegistrationStepsLoaded,
  organisationRegistrationStepsNotLoaded,
  registeredOrganisationLoaded,
  registeredOrganisationNotLoaded,
  registerOrganisation,
  registrationDefaultOrganisationLoaded,
  registrationDefaultOrganisationNotLoaded,
  setCurrentOrganisation,
  setUserDefaultOrganisation,
  userDefaultOrganisationLoaded,
  userOrganisationsLoaded
} from './organisations.actions';
import { Observable, forkJoin, mergeMap, of, switchMap, take } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { UserOrganisations } from './organisations.state';
import {
  IRegisterOrganisationRequestWithMoreParams,
  IRegisterOrganisationResponse,
  IRegistrationCurrentDefaultOrganisation,
  IRegistrationDialogStep
} from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import { Organization } from '../../models/organization.model';
import { RegistrationDialogError } from '../global/global.state';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class OrganisationsEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private auth: AuthService
  ) {}

  loadUserOrganisationsData$ = createEffect(() => {
    let tempUserOrganisations: any[];
    let tempResult: any;

    return this.actions$.pipe(
      ofType(loadUserOrganisations),
      mergeMap(action =>
        // 1st Phase: get organisations related to the user
        this.apiService.getOrganisations(action.userId).pipe(
          switchMap((result: any) => {
            const obsArray: Observable<any>[] = [];
            tempUserOrganisations = this.initUserOrganisations(
              result.organizations
            );

            tempResult = result;
            // 2nd Phase: prepare the observables for each organisation
            for (const orgId in tempUserOrganisations) {
              obsArray.push(
                this.auth
                  .getTokenSilently$()
                  .pipe(
                    switchMap(token =>
                      this.apiService.getOrganisation(orgId, token)
                    )
                  )
              );
            }

            if (!obsArray.length) {
              return of([]);
            } else {
              return forkJoin(obsArray);
            }
          }),
          map((organisationsDetails: any[]) => {
            if (!organisationsDetails.length) {
              // No need to fetch the orga. details in case the user has no organisations related to its uuid
              return userOrganisationsLoaded({
                userOrganisations: this.initUserOrganisations(
                  tempResult.organizations
                )
              });
            } else {
              // 3rd Phase: get the organisations details
              return userOrganisationsLoaded({
                userOrganisations: this.getNewUserOrganisations(
                  organisationsDetails,
                  tempUserOrganisations
                )
              });
            }
          }),
          catchError(error =>
            of(organisationDataNotLoaded({ organisationError: error.payload }))
          )
        )
      )
    );
  });

  loadUserDefaultOrganisationData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUserDefaultOrganisation),
      mergeMap(action =>
        this.apiService.getDefaultOrganisation(action.userId).pipe(
          map((result: any) =>
            userDefaultOrganisationLoaded({
              defaultOrganisationId: result.orgId
            })
          )
        )
      )
    );
  });

  setUserDefaultOrganisation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setUserDefaultOrganisation),
      mergeMap(action =>
        this.apiService
          .setDefaultOrganisation({ orgId: action.orgId }, action.userId)
          .pipe(
            map((result: any) =>
              userDefaultOrganisationLoaded({
                defaultOrganisationId: result.orgId
              })
            )
          )
      )
    );
  });

  loadOrganisationRegistrationStepsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadOrganisationRegistrationSteps),
      filter(steps => !!steps),
      take(1),
      switchMap(() =>
        this.apiService.getRegistrationSteps().pipe(
          map(result => result.steps),
          map((result: IRegistrationDialogStep[]) =>
            organisationRegistrationStepsLoaded({
              registrationSteps: result
            })
          ),
          catchError(error =>
            of(
              organisationRegistrationStepsNotLoaded({
                organisationError: error.payload
              })
            )
          )
        )
      )
    );
  });

  loadRegistrationDefaultOrganisation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadRegistrationDefaultOrganisation),
      switchMap(action => {
        return this.apiService
          .getRegistrationDefaultOrganisation(action.country)
          .pipe(
            map((result: IRegistrationCurrentDefaultOrganisation) =>
              registrationDefaultOrganisationLoaded({
                registrationDefaultOrganisation: result
              })
            ),
            catchError(error =>
              of(
                registrationDefaultOrganisationNotLoaded({
                  organisationError: error.payload
                })
              )
            )
          );
      })
    );
  });

  registerOrganisation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(registerOrganisation),
      switchMap((request: IRegisterOrganisationRequestWithMoreParams) => {
        return this.apiService.registerOrganisation(request).pipe(
          map((response: IRegisterOrganisationResponse) => {
            return registeredOrganisationLoaded({
              registeredOrganisation: {
                response,
                newOrganisation: true,
                country: request.country
              }
            });
          }),
          catchError(error =>
            of(
              registeredOrganisationNotLoaded({
                registrationError: RegistrationDialogError.STEP_1,
                organisationError: error.payload
              })
            )
          )
        );
      })
    );
  });

  setCurrentOrganisation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setCurrentOrganisation),
      map((action: Partial<Organization>) =>
        currentOrganisationLoaded({
          currentOrganisation: {
            auth0Id: action.auth0Id
          }
        })
      )
    );
  });

  loadAddressDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadAddressDetails),
      switchMap((action: any) => {
        return this.apiService
          .getRegistrationAddressDetails(action.addressDetailsRequest)
          .pipe(
            map(result => addressDetailsLoaded({ addressDetails: result })),
            catchError(error =>
              of(addressDetailsNotLoaded({ organisationError: error.payload }))
            )
          );
      })
    );
  });

  initUserOrganisations(organisationsIds: string[]): any {
    const userOrganisations: UserOrganisations = {};

    for (let i = 0; i < organisationsIds.length; i++) {
      const key = organisationsIds[i];

      if (key) {
        userOrganisations[key] = {};
      }
    }

    return userOrganisations;
  }

  getNewUserOrganisations(
    organisationsDetails: any[],
    userOrganisations: UserOrganisations
  ): UserOrganisations {
    const newUserOrganisations: UserOrganisations = {};

    const filteredOrgs: any = organisationsDetails.filter(
      organisation => organisation.registered
    );

    filteredOrgs.forEach((org: any) => {
      const orgId: string = org.auth0Id;

      if (userOrganisations[orgId]) {
        newUserOrganisations[orgId] = org;
      }
    });

    return newUserOrganisations;
  }
}
