import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogWindowComponent,
  ResponseDataFromDialog,
  passedDataToDialog
} from '../shared/components/dialog-window/dialog-window.component';
import { filter, map, tap } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { Icon } from '@claas/claas-form-components';
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { PhoneNumbers } from '../shared/models/phone-numbers-model';
import {
  CircularProgressDiameter,
  DividerOrientation,
  ProgressColor
} from '@claas/claas-layout-components';
import {
  ButtonClass,
  ButtonSize,
  IconFontSize,
  IconSize
} from '@claas/claas-form-components';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Store } from '@ngrx/store';
import { selectUserCoordinates } from '../shared/state/user-coordinates/user-coordinates.selectors';
import { loadUserCoordinates } from '../shared/state/user-coordinates/user-coordinates.actions';
import { UserInformationModel } from '../shared/models/user-information.model';
import { countriesArr } from '../shared/components/language-view/countries-source';
import { UserUpdateRequest } from '../shared/models/user-update-request.model';
import {
  allAuthActions,
  selectUserAvatarUrl,
  selectUserDetails
} from '../shared/state/user';

export interface UserBasicInformation {
  salutation?: string;
  title?: string;
  given_name?: string;
  family_name?: string;
  language?: string;
  country?: string;
}

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss']
})
export class PersonalDataComponent implements AfterViewInit, OnDestroy {
  countriesArray = countriesArr;
  avatarUrl = '';
  userInformation = new UserInformationModel();
  userUpdateRequest: UserUpdateRequest | undefined;
  userPhoneNumbers: any[] = [];
  editIcon: Icon = {
    iconId: '001326',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };
  addIcon: Icon = {
    iconId: '000973',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 19
  };
  largeSize = CircularProgressDiameter.LARGE;
  secondaryColor = ProgressColor.SECONDARY;
  primaryTextButtonClass = ButtonClass.PRIMARY_TEXT;
  mediumButtonSize = ButtonSize.DEFAULT_MEDIUM;
  mediumIconSize = IconSize.MEDIUM;
  mediumIconFontSize = IconFontSize.MEDIUM;
  horizontalDivider = DividerOrientation.HORIZONTAL;
  avatarUrlIsDefined$ = new Subject<boolean>();
  phonenumberAreDefined$ = new Subject();
  addressesAreDefined$ = new Subject();
  userLanguage = '';
  isHandset = false;
  private subscriptions = new Subscription();
  private currentContext: undefined | string;

  constructor(
    public store: Store,
    public dialog: MatDialog,
    public authService: AuthService,
    private apiService: ApiService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.subscriptions.add(this.isHandset$().subscribe());
  }

  ngAfterViewInit() {
    this.store
      .select(selectUserDetails)
      .pipe(filter(user => !!user))
      .subscribe(() => {
        this.getUserProfile();
      });

    this.store.select(selectUserAvatarUrl).subscribe(url => {
      if (url) {
        this.avatarUrl = url;
        this.avatarUrlIsDefined$.next(true);
      }
    });
  }

  isHandset$(): Observable<any> {
    return this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map((state: any) => (this.isHandset = state.matches)));
  }

  getCountry(country: string, prop: string) {
    if (!country) {
      return '';
    }
    const c = this.countriesArray.find(function (el) {
      return el.id === country.toLowerCase();
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return c === undefined ? '' : c[prop];
  }

  openDialog(
    context: string,
    subContext?: string,
    currentElementIndex?: number
  ): void {
    this.currentContext = context;
    const data: passedDataToDialog = {
      context,
      buttonCancel: 'Cancel',
      buttonSave: 'Save'
    };

    if (context === 'profile-avatar') {
      data['avatarUrl'] = this.avatarUrl;
      data.buttonDelete = 'Delete';
    }

    if (context === 'profile-name') {
      data['nameDetails'] = this.userInformation;
    }

    if (context === 'profile-phonenumber') {
      data['subContext'] = subContext;
      data['countriesForPhone'] = [];
      data['countriesForPhone'].push(
        this.userInformation.country.toLowerCase()
      );

      if (subContext === 'edit') {
        data.buttonDelete = 'Delete';

        if (currentElementIndex === 0 || currentElementIndex) {
          data['phonenumberDetails'] = {
            currentIndex: currentElementIndex,
            phoneNumbers: this.userPhoneNumbers
          };
        }
      }

      if (subContext === 'add') {
        data.phonenumberDetails = {
          phoneNumbers: this.userPhoneNumbers
        };
      }
    }

    if (context === 'profile-address') {
      data['subContext'] = subContext;
      data['userLanguage'] = this.userLanguage;

      if (subContext === 'edit') {
        data.buttonDelete = 'Delete';

        if (
          (currentElementIndex === 0 || currentElementIndex) &&
          this.userInformation?.addresses
        ) {
          data['addressDetails'] = {
            currentIndex: currentElementIndex,
            addresses: this.userInformation?.addresses
          };
        }
      }

      if (subContext === 'add') {
        if (this.userInformation?.addresses) {
          data['addressDetails'] = {
            addresses: this.userInformation?.addresses
          };

          data['nameDetails'] = this.userInformation;
        }

        data.userCountry = this.userInformation.country.toLowerCase();
      }
    }

    const dialogRef = this.dialog.open(DialogWindowComponent, {
      maxWidth: this.isHandset ? '100vw' : '80vw',
      data
    });

    this.subscriptions.add(
      dialogRef
        .afterClosed()
        .pipe(
          tap((answer: ResponseDataFromDialog) => {
            if (answer?.refreshIsRequired) {
              switch (this.currentContext) {
                case 'profile-avatar':
                  this.reloadProfilePicture();
                  break;
                case 'profile-name':
                  this.store.dispatch(loadUserCoordinates());
                  break;
                case 'profile-phonenumber':
                  this.reloadPhonenumbers();
                  break;
                case 'profile-address':
                  this.reloadAddresses();
                  break;
              }
            }
          })
        )
        .subscribe()
    );
  }

  getUserProfile(): void {
    this.subscriptions.add(
      this.store.select(selectUserCoordinates).subscribe((coordinates: any) => {
        if (!coordinates) {
          this.store.dispatch(loadUserCoordinates());
        } else {
          this.userInformation = coordinates;
          this.userLanguage = this.userInformation?.language
            ? this.userInformation?.language.toLowerCase()
            : '';
          this.addressesAreDefined$.next(
            this.userInformation?.addresses
              ? this.userInformation?.addresses
              : []
          );

          const phoneNumbers: PhoneNumbers = {
            phone_number_value: this.userInformation?.phone_number_value,
            mobile_number_value: this.userInformation?.mobile_number_value
          };

          this.updateUserPhoneNumbersLocally(phoneNumbers);
        }
      })
    );
  }

  reloadProfilePicture(): void {
    this.avatarUrlIsDefined$.next(false);

    this.store.dispatch(allAuthActions.loadUserAvatarUrl());
  }

  reloadPhonenumbers(): void {
    this.phonenumberAreDefined$.next(true);
    this.getUserPhoneNumbers();
  }

  getUserPhoneNumbers(): void {
    this.subscriptions.add(
      this.apiService.listUserPhoneNumbers().subscribe((result: any) => {
        this.updateUserPhoneNumbersLocally(result);
      })
    );
  }

  updateUserPhoneNumbersLocally(result: PhoneNumbers): void {
    this.userPhoneNumbers = [];

    if (result?.phone_number_value) {
      const phoneNumber: any = {
        type: 'phone',
        phone_number_value: result.phone_number_value
      };

      this.userPhoneNumbers.push(phoneNumber);
    }

    if (result?.mobile_number_value) {
      const phoneNumber: any = {
        type: 'mobile',
        phone_number_value: result.mobile_number_value
      };

      this.userPhoneNumbers.push(phoneNumber);
    }

    this.phonenumberAreDefined$.next(true);
  }

  reloadAddresses(): void {
    this.addressesAreDefined$.next(false);
    this.store.dispatch(loadUserCoordinates());
  }

  getUserUpdateRequest(): void {
    this.subscriptions.add(
      this.apiService.getUserUpdateRequest().subscribe((result: any) => {
        this.userUpdateRequest = result;

        if (this.userUpdateRequest && this.dialog.openDialogs.length === 0) {
          this.openDialog('user-update');
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
