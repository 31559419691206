<div class="organisation-data-dealers">
  <div
    *ngIf="
      (organisationDefaultDealerInfo$ | async) === false &&
        !organisationShops &&
        !organisationConnectedDealers;
      else dealersListing
    ">
    <div class="organisation-data-dealers__loader">
      <claas-circular-progress
        [color]="secondaryColor"
        [diameter]="largeSize"></claas-circular-progress>
    </div>
  </div>

  <ng-template #dealersListing>
    <section class="organisation-data-dealers__mydealer">
      <div class="organisation-data-dealers__mydealer-header">
        <h3
          class="organisation-data-dealers__mydealer-header--title header-3-light">
          {{ 'organization.dealer_header' | translate }}
        </h3>

        <div class="organisation-data-dealers__mydealer-header--action">
          <claas-icon-button
            *ngIf="isAdmin"
            class="edit-btn"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="openDefaultDealerDialog()"
          ></claas-icon-button>
        </div>
      </div>
      <div class="organisation-data-dealers__mydealer-content">
        <div class="organisation-data-dealers__mydealer-content--left">
          <span class="logo-icon"></span>
        </div>

        <div *ngIf="(organisationDefaultDealerInfo$ | async) === false"
             class="organisation-data-dealers__mydealer-content--right">
          <claas-circular-progress
            [color]="secondaryColor"
            [diameter]="largeSize"></claas-circular-progress>
        </div>

        <div *ngIf="organisationDefaultDealerInfo$ | async"
              class="organisation-data-dealers__mydealer-content--right">
          <div class="mydealer-info header-3-bold legalname">
            <span>{{ organisationDefaultDealer?.name }}</span>
          </div>

          <div class="mydealer-info">
            <span *ngIf="organisationDefaultDealer?.phone"
              >{{ 'organization.dealer_phone' | translate }} {{ organisationDefaultDealer?.phone }}
            </span>
          </div>

          <div class="mydealer-info">
            <span>{{ organisationDefaultDealer?.email }} </span>
          </div>

          <div class="mydealer-info website">
            <a
              class="mydealer-info--link"
              mat-button
              [href]="organisationDefaultDealer?.url"
              target="_blank"
              >{{ organisationDefaultDealer?.url }}</a
            >
          </div>

          <div class="mydealer-info">
            <span>{{ organisationDefaultDealer?.street }}<br /></span>
            <span
              >{{ organisationDefaultDealer?.postalCode }}
              {{ organisationDefaultDealer?.city }},
              {{ organisationDefaultDealer?.country | asString | translate }}
            </span>
          </div>
        </div>
      </div>
    </section>

    <section class="organisation-data-dealers__myshops">
      <div class="organisation-data-dealers__myshops-header">
        <h3
          class="organisation-data-dealers__myshops-header--title header-3-light">
          {{ 'organization.shops_header' | translate }}
        </h3>

        <div class="organisation-data-dealers__myshops-header--action">
          <claas-icon-button
            *ngIf="isAdmin"
            class="edit-btn"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="editShops()"></claas-icon-button>
        </div>
      </div>
      <div
        *ngIf="organisationShops && organisationShops.length"
        class="organisation-data-dealers__myshops-content">
        <div *ngFor="let shop of organisationShops" class="shop-info">
          <div class="shop-info--left">
            <div class="logo-container">
              @if (shop.translationKey == 'COLLECTION') {
                <span class="logo-icon collection"></span>
              }
              @else if (shop.translationKey == 'FARM_PARTS') {
                <span class="logo-icon farmparts"></span>
              }
              @else if (shop.translationKey == 'EASY') {
                <span class="logo-icon easy"></span>
              }
              @else if (shop.translationKey == 'FIRST_CLAAS_USED') {
                <span class="logo-icon firstclaasused"></span>
              }
              @else if (shop.translationKey == 'PARTS_DOC') {
                <span class="logo-icon partsdoc"></span>
              }
              @else if (shop.translationKey == 'PARTS_ONLINE') {
                <span class="logo-icon partsshop"></span>
              }
              @else if (shop.translationKey == 'ET') {
                <span class="logo-icon partsshop"></span>
              }
              @else {
                <span class="logo-icon"></span>
              }
            </div>
          </div>
          <div class="shop-info--right">
            <div class="shop-info--right_title">
              <h3 class="header-3-bold">{{ 'shops.shopType.'+(shop.translationKey | lowercase)+'.title' | translate }}</h3>
            </div>

            <a class="shop-info--right_text">{{ 'shops.shopType.'+(shop.translationKey | lowercase)+'.text' | translate }}</a>
            <a
              class="shop-info--right_link"
              mat-button
              [href]="shop.url"
              target="_blank"
              >{{ 'organization.to_shop' | translate }}<lib-icon [iconId]="'001476'" [size]="18"></lib-icon></a
            >
          </div>
        </div>
      </div>

      <div
        *ngIf="organisationShops && !organisationShops.length"
        class="organisation-data-dealers__myshops-content">
        <span class="no-shops">{{
          'organization.no_shops' | translate
        }}</span>
      </div>

      <div
        *ngIf="!organisationShops"
        class="organisation-data-dealers__myshops-content">
        <claas-circular-progress
          [color]="secondaryColor"

          [diameter]="largeSize"></claas-circular-progress>
      </div>
    </section>

    <section class="organisation-data-dealers__others">
      <div class="organisation-data-dealers__others-header">
        <h3
          class="organisation-data-dealers__others-header--title header-3-light">
          {{ 'organization.connected_dealers' | translate }}
        </h3>

        <div class="organisation-data-dealers__others-header--action">
          <claas-icon-button
            *ngIf="isAdmin && organisationConnectedDealers && organisationConnectedDealers.length"
            class="edit-btn"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="openDialog('connected-dealers')"></claas-icon-button>
        </div>
      </div>

      <div
        *ngIf="
          organisationConnectedDealers && organisationConnectedDealers.length
        "
        class="organisation-data-dealers__others-content">
        <div
          *ngFor="let element of organisationConnectedDealers"
          class="element-info">
          <div class="element-info--left">
            <div class="logo-container">
              <span class="logo-icon connected"></span>
            </div>
          </div>
          <div class="element-info--right">
            <div class="element-info--right_title">
              <h3 class="header-3-bold">{{ element.name }}</h3>
            </div>

            <div class="element-info--right_content">
              <span
                >{{ element.street }}, {{ element.postalCode }}
                {{ element.state }} {{ element.country | translate }}</span
              ><br />
              <span>{{ 'organization.dealer_phone' | translate }} {{ element.phone }}</span
              ><br />
              <span>{{ element.email }}</span
              ><br />
              <span>{{ element.url }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="
          organisationConnectedDealers && !organisationConnectedDealers.length
        "
        class="organisation-data-dealers__others-content">
        <span class="no-connected-dealers">{{
          'organization.no_connected_dealers' | translate
        }}</span>
      </div>

      <div
        *ngIf="!organisationConnectedDealers"
        class="organisation-data-dealers__others-content">
        <claas-circular-progress
          [color]="secondaryColor"

          [diameter]="largeSize"></claas-circular-progress>
      </div>
    </section>
  </ng-template>
</div>
