// important: The application ID needs to be equal to key definition in the i18n files
import { environment } from '../../environments/environment';

export const consentsArr = [
  {
    id: 'CLAAS_collection',
    label: 'applications.CLAAS_collection',
    client_id: environment.client_ids.auth_collection_id,
    img: '/assets/images/collection_shop.png',
    url: environment.base_urls.collection_base_url
  },
  { id: 'CLAAS_connect_3',
    label: 'applications.CLAAS_connect_3',
    client_id: environment.client_ids.auth_connect_3_id,
    img: '/assets/images/017904.svg',
    url: environment.base_urls.cc_3_base_url
  },
  { id: 'CLAAS_connect_3_app',
    label: 'applications.CLAAS_connect_3_app',
    client_id: environment.client_ids.auth_connect_3_app_id,
    img: '/assets/images/CLAAS connect_App.svg',
    url: environment.base_urls.cc_3_app_base_url
  },
  {
    id: 'ET',
    label: 'applications.ET',
    client_id: environment.client_ids.auth_et_id,
    img: '/assets/images/ps_logo.jpg',
    url: environment.base_urls.et_base_url
  },
  {
    id: 'ET_UK',
    label: 'applications.ET_UK',
    client_id: environment.client_ids.auth_et_id_uk,
    img: '/assets/images/ps_logo.jpg',
    url: environment.base_urls.et_base_url_uk
  },
  {
    id: 'ET_FR',
    label: 'applications.ET_FR',
    client_id: environment.client_ids.auth_et_id_fr,
    img: '/assets/images/ps_logo.jpg',
    url: environment.base_urls.et_base_url_fr
  },
  {
    id: 'ET_NL',
    label: 'applications.ET_NL',
    client_id: environment.client_ids.auth_et_id_nl,
    img: '/assets/images/ps_logo.jpg',
    url: environment.base_urls.et_base_url_nl
  },
  {
    id: 'ET_PL',
    label: 'applications.ET_PL',
    client_id: environment.client_ids.auth_et_id_pl,
    img: '/assets/images/ps_logo.jpg',
    url: environment.base_urls.et_base_url_pl
  },
  {
    id: 'ET_RO',
    label: 'applications.ET_RO',
    client_id: environment.client_ids.auth_et_id_ro,
    img: '/assets/images/ps_logo.jpg',
    url: environment.base_urls.et_base_url_ro
  },
  {
    id: 'ET_AT',
    label: 'applications.ET_AT',
    client_id: environment.client_ids.auth_et_id_at,
    img: '/assets/images/ps_logo.jpg',
    url: environment.base_urls.et_base_url_at
  },
  {
    id: 'ET_DK',
    label: 'applications.ET_DK',
    client_id: environment.client_ids.auth_et_id_dk,
    img: '/assets/images/ps_logo.jpg',
    url: environment.base_urls.et_base_url_dk
  },
  {
    id: 'ET_CH',
    label: 'applications.ET_CH',
    client_id: environment.client_ids.auth_et_id_ch,
    img: '/assets/images/ps_logo.jpg',
    url: environment.base_urls.et_base_url_ch
  },
  {
    id: 'ET_SK',
    label: 'applications.ET_SK',
    client_id: environment.client_ids.auth_et_id_sk,
    img: '/assets/images/ps_logo.jpg',
    url: environment.base_urls.et_base_url_sk
  },
  {
    id: 'ET_CZ',
    label: 'applications.ET_CZ',
    client_id: environment.client_ids.auth_et_id_cz,
    img: '/assets/images/ps_logo.jpg',
    url: environment.base_urls.et_base_url_cz
  },
  {
    id: 'ET_LT',
    label: 'applications.ET_LT',
    client_id: environment.client_ids.auth_et_id_lt,
    img: '/assets/images/ps_logo.jpg',
    url: environment.base_urls.et_base_url_lt
  },
  {
    id: 'ET_EE',
    label: 'applications.ET_EE',
    client_id: environment.client_ids.auth_et_id_ee,
    img: '/assets/images/ps_logo.jpg',
    url: environment.base_urls.et_base_url_ee
  },
  {
    id: 'ET_LV',
    label: 'applications.ET_LV',
    client_id: environment.client_ids.auth_et_id_lv,
    img: '/assets/images/ps_logo.jpg',
    url: environment.base_urls.et_base_url_lv
  },
  {
    id: 'ET_AR',
    label: 'applications.ET_AR',
    client_id: environment.client_ids.auth_et_id_ar,
    img: '/assets/images/ps_logo.jpg',
    url: environment.base_urls.et_base_url_ar
  },
  {
    id: 'CLAAS_connect_farm',
    label: 'applications.CLAAS_connect_farm',
    client_id: environment.client_ids.claas_connect_farm,
    img: '/assets/images/750146.svg',
    url: environment.base_urls.claas_connect_farm
  },
  {
    id: 'CLAAS_connect_farm_app',
    label: 'applications.CLAAS_connect_farm_app',
    client_id: environment.client_ids.claas_connect_farm_app,
    img: '/assets/images/CLAAS_connect_farm_App.svg',
    url: environment.base_urls.claas_connect_farm_app
  }
];
